import Phaser from 'phaser';
import SceneActions from '../utils/sceneActions';
import GraphicsService from '../services/graphicsService';
import AuthRequest from '../services/authService';
import GameService from '../services/gameService';

export class Stats extends Phaser.Scene {
    constructor() {
        super({ key: 'Stats' });
        this.sceneActions = new SceneActions(this);
        this.graphicsService = new GraphicsService(this);
        this.authRequest = new AuthRequest(process.env.HISTORIAL_USUARIO);
        this.gameService = new GameService(this);
        this.characterList = JSON.parse(localStorage.getItem('characters')) || [];
        this.soundService = window.soundService;
    }

    preload() {
        this.load.setPath('assets/');
        this.load.image('line', 'images/stats/line.png');
        this.load.image('stats', 'images/stats/profile-stats.png');
        this.load.image('aro', 'images/champion-select/aro.png');    
    }

    async create() {
        const userId = localStorage.getItem('iduser');
        const endpoint = `${process.env.HISTORIAL_USUARIO}?user_id=${userId}`;
        try {
            this.estadisticas = await this.gameService.loadPlayerHistory(this.authRequest, endpoint);
        } catch (error) {
            console.error('Error al cargar las estadísticas:', error);
        }

        this.setupScene();
        this.setupInput();
        this.readPlayerStats();
    }

    setupScene() {
        this.cameras.main.fadeIn(1500, 0, 0, 0);
        this.graphicsService.addGeneralBackground('blueSky');
        this.add.image(this.cameras.main.centerX, this.cameras.main.height / 2 - 350, 'stats').setOrigin(0.5);
        this.add.image(this.cameras.main.centerX / 2, this.cameras.main.centerY * 1.2, 'aro');
    }

    setupInput() {
        const returnButton = this.add.image(this.cameras.main.centerX * 0.13, this.cameras.main.centerY * 1.9, 'return')

        const keyActions = {
            Escape: () => {
                this.soundService.playSfx('click');
                this.sceneActions.movetoScene('Menu')
            }
        };

        this.input.keyboard.on('keydown', (event) => {
            const action = keyActions[event.key];
            if (action) {
                action();
            }
        });

        this.setButtonInteraction(returnButton, () => {
            this.soundService.playSfx('click');
            this.sceneActions.movetoScene('Menu')
        })
    }

    setButtonInteraction(button, action, click) {
        button.on('pointerdown', () => {
            click.play();
            action();
        });
    }

    readPlayerStats() {
        let stats_shown = [
            {label: 'TOTAL DAMAGE', value: this.estadisticas?.totalDamage || 0},
            {label: 'TOTAL KILLS', value: this.estadisticas?.totalKills || 0},
            {label: 'TOTAL DIES', value: this.estadisticas?.totalDies || 0},
            {label: 'LARGEST KILL SPREE', value: this.estadisticas?.largestKillSpree || 0},
            {label: 'FAVORITE MAP', value: this.estadisticas?.mostUsedMap || 'No Games Found'},
        ];

        let posY = this.cameras.main.centerY - 150;
        for (let i = 0; i < stats_shown.length; i++) {
            this.addPlayerStat(stats_shown[i].label, stats_shown[i].value, posY);
            posY += 100;
        }

        let allStatsAreZero = stats_shown.every(stat => stat.value === 0 || stat.value === 'No Games Found');
        if (!allStatsAreZero) {
            this.addFavoriteChampion();
        }
    }

    addPlayerStat(label, value, posY) {
        const labelX = this.cameras.main.centerX + 100;
        const valueX = this.cameras.main.centerX + 450;
        this.add.text(labelX, posY, label, { fontSize: '32px', color: '#ffffff', fontFamily: 'Rubik', align: 'left' }).setOrigin(1, 0.5);
        this.add.text(valueX, posY, value, { fontSize: '32px', color: '#ffffff', fontFamily: 'Rubik', align: 'center' }).setOrigin(0, 0.5);
        this.add.image(this.cameras.main.centerX +50, posY + 40, 'line').setScale(0.20, 0.4).setOrigin(0.33, 0.33);
    }

    addFavoriteChampion() {
        const favoriteChampion = this.estadisticas.favoriteChampion;

        if (favoriteChampion ) {
            let imageUrl = `${process.env.PERSONAJES_STORAGE_PATH}${favoriteChampion.toLowerCase()}/preview.png`;
            this.load.image(`${favoriteChampion.toLowerCase()}-preview`, imageUrl);
            this.load.once('complete', () => {
                this.add.image(this.cameras.main.centerX / 2, this.cameras.main.centerY*1.2, `${favoriteChampion.toLowerCase()}-preview`).setOrigin(0.5).setScale(0.5);
                this.add.text(this.cameras.main.centerX / 2, this.cameras.main.centerY/1.3, favoriteChampion, {
                    fontSize: '36px', color: '#ffffff', fontFamily: 'Rubik', align: 'center'
                }).setOrigin(0.5);
            });
            this.load.start();
        } else {
            this.add.text(this.cameras.main.centerX / 2, this.cameras.main.centerY, 'NO GAMES FOUND', {
                fontSize: '36px', color: '#ffffff', fontFamily: 'Rubik', align: 'center'
            }).setOrigin(0.5);
        }
    }
}

export default Stats;
