import GraphicsService from '../services/graphicsService';
import SceneActions from '../utils/sceneActions.js';
import Phaser from 'phaser';

export class SelectChar extends Phaser.Scene {
    constructor() {
        super({ key: 'SelectChar' });
        this.graphicsService = new GraphicsService(this);
        this.player1Index = 0;
        this.player2Index = 1;
        this.player1Selected = false;
        this.player2Selected = false;
        this.characters = [];
        this.sceneActions = new SceneActions(this);
        this.soundService = window.soundService;   
    }

    async preload() {
        this.reset();
        this.load.setPath('assets/');
        this.load.image('marco', 'images/champion-select/arrows/marco-select.png');
        this.load.image('p1', 'images/champion-select/arrows/player1.png');
        this.load.image('p2', 'images/champion-select/arrows/player2.png');
        this.load.image('aro', 'images/champion-select/aro.png');
        this.load.image('vs', 'images/champion-select/vs.png');
        this.load.image('selectp1', 'images/champion-select/selectp1.png');
        this.load.image('selectp2', 'images/champion-select/selectp2.png');
        this.load.image('selectp1p2', 'images/champion-select/selectp1p2.png');
        this.load.image('skyNoLogo', 'images/champion-select/skyNoLogo.jpg');

        this.characters = JSON.parse(localStorage.getItem('characters')) || [];
        this.mapList = JSON.parse(localStorage.getItem('maps')) || [];
    }

    create() {
        
        this.setupScene();
        this.setupInput();
        this.createCharacterSelectionUI();
        this.showSelectedCharacter(1, this.player1Index);
        this.showSelectedCharacter(2, this.player2Index);

        const selectedMap = this.registry.get('SelectMap');
        const selectedMapInfo = this.mapList.find(map => map.name === selectedMap.name);
        if (selectedMapInfo) {
            this.load.image(selectedMapInfo.name, `images/champion-select/map-info/${selectedMapInfo.name}.png`);
            this.load.once('complete', () => {
                this.add.image(this.cameras.main.width / 2, this.cameras.main.height / 2 - 400, selectedMapInfo.name).setOrigin(0.5);
            });
            this.load.start();
        }
        this.startTextBlink();
    }

    setupScene() {
        this.cameras.main.fadeIn(1500, 0, 0, 0);
        this.graphicsService.addGeneralBackground('skyNoLogo')
        const screenHeight = this.cameras.main.height;
        const screenWidth = this.cameras.main.width;
        this.add.image(screenWidth / 2, screenHeight / 1.7, 'vs').setScale(0.83);
        this.add.image(screenWidth / 4, screenHeight / 2, 'aro');
        this.add.image(screenWidth / 1.33, screenHeight / 2, 'aro');
        this.add.image(screenWidth / 4, screenHeight / 1.2, 'p1');
        this.add.image(screenWidth / 1.33, screenHeight / 1.2, 'p2');
        this.add.image(this.cameras.main.centerX * 0.13, this.cameras.main.centerY * 1.9, 'return');

        this.player1Frame = this.add.image(this.cameras.main.centerX - 170, this.cameras.main.height - 125, 'selectp1').setDepth(1);
        this.player2Frame = this.add.image(this.cameras.main.centerX - 0, this.cameras.main.height - 125, 'selectp2').setDepth(1);
    }

    setupInput() {
        this.controlsp1 = localStorage.getItem('controlsP1') ? JSON.parse(localStorage.getItem('controlsP1')) : { up: 'UP', down: 'DOWN', left: 'LEFT', right: 'RIGHT', attack: 'N', kick: 'M' };
        this.controlsp2 = localStorage.getItem('controlsP2') ? JSON.parse(localStorage.getItem('controlsP2')) : { up: 'W', down: 'S', left: 'A', right: 'D', attack: 'T', kick: 'Y' };

        const formFontStyle = {
            fontSize: '36px',
            fontFamily: 'Rubik',
            color: '#ffffff',
            align: 'center',
            fontWeight: 'bold',
            fontStyle: 'bold'
        }
        
        const player1Container = this.add.container(this.cameras.main.width / 4, this.cameras.main.height / 1.35);
        this.textPlayer1 = this.add.text(0, 0, `Press ${this.controlsp1.attack} key to select a hero`, formFontStyle ).setOrigin(0.5);
        player1Container.add(this.textPlayer1)
        
        const player2Container = this.add.container(this.cameras.main.width / 1.33, this.cameras.main.height / 1.35);
        this.textPlayer2 = this.add.text(0, 0, `Press ${this.controlsp2.attack} key to select a hero`, formFontStyle ).setOrigin(0.5);
        player2Container.add(this.textPlayer2)

        this.input.keyboard.on('keydown', (event) => {
            this.handlePlayerInput(event);
        });

        this.alertContainer = this.add.container(this.cameras.main.width/2, this.cameras.main.height/2 - 100).setVisible(false);
        this.alertMessage = this.add.text(0,0, 'You have to choose other character!', formFontStyle ).setDepth(1).setOrigin(0.5);
        this.alertContainer.add(this.alertMessage);
    }

    handlePlayerInput(event) {
        if (!this.player1Selected) {
            this.handlePlayerGenericInput(event, 1, this.controlsp1.left, this.controlsp1.right, this.controlsp1.attack);
        }
        if (!this.player2Selected) {
            this.handlePlayerGenericInput(event, 2, this.controlsp2.left, this.controlsp2.right, this.controlsp2.attack);
        }
    
        if (this.player1Selected && this.player2Selected) {
            this.startGame();
        }
    
        this.updateFramePosition();
        this.showSelectedCharacter(1, this.player1Index);
        this.showSelectedCharacter(2, this.player2Index);
    }

    handlePlayerGenericInput(event, player, leftKey, rightKey, selectKey) {
        const keyActions = {
            [leftKey]: () => {
                this.soundService.playSfx('hover');
                if (player === 1) {
                    do {
                        this.player1Index = Math.max(0, this.player1Index - 1);
                    } while (this.player1Index === this.player2Index && this.player1Index > 0);
                    this.updateFramePosition(1);
                } else {
                    do {
                        this.player2Index = Math.max(0, this.player2Index - 1);
                    } while (this.player2Index === this.player1Index && this.player2Index > 0);
                    this.updateFramePosition(2);
                }
            },
            [rightKey]: () => {
                this.soundService.playSfx('hover');
                if (player === 1) {
                    do {
                        this.player1Index = Math.min(this.characters.length - 1, this.player1Index + 1);
                    } while (this.player1Index === this.player2Index && this.player1Index < this.characters.length - 1);
                    this.updateFramePosition(1);
                } else {
                    do {
                        this.player2Index = Math.min(this.characters.length - 1, this.player2Index + 1);
                    } while (this.player2Index === this.player1Index && this.player2Index < this.characters.length - 1);
                    this.updateFramePosition(2);
                }
            },
            [selectKey]: () => {
                this.soundService.playSfx('click');
                if (player === 1 && this.player1Index !== this.player2Index) {
                    this.player1Selected = true;
                    this.showSelectedCharacter(1, this.player1Index);
                    this.onKeyPressed('1'); 
                } else if (player === 2 && this.player2Index !== this.player1Index) {
                    this.player2Selected = true;
                    this.showSelectedCharacter(2, this.player2Index);
                    this.onKeyPressed('2'); 
                }

                if ((player === 1 && this.player2Index === this.player1Index) || (player === 2 && this.player1Index === this.player2Index)) {
                    this.alertContainer.setVisible(true)
                    setTimeout(() => {
                        this.alertContainer.setVisible(false)
                    }, 2000);
                    return; 
                }
            },
            ESCAPE: () => {
                this.soundService.playSfx('click');
                this.registry.remove('SelectMap');
                this.sceneActions.movetoScene('SelectMap');
            }
        };
    
        const action = keyActions[event.key.toUpperCase()];
        if (action) {
            action();
        }
    }
    
    updateFramePosition() {
        const x1 = this.cameras.main.centerX - 170 + this.player1Index * this.characterSpacing;
        const x2 = this.cameras.main.centerX - 170 + this.player2Index * this.characterSpacing;
        const y = this.cameras.main.height - 125;
    
        if (this.player1Index === this.player2Index) {
            this.player1Frame.setTexture('selectp1p2').setPosition(x1, y);
            this.player2Frame.setVisible(false);
        } else {
            this.player1Frame.setTexture('selectp1').setPosition(x1, y);
            this.player2Frame.setTexture('selectp2').setPosition(x2, y).setVisible(true);
        }
    }

    showSelectedCharacter(player, index) {
        const screenWidth = this.cameras.main.width;
        const screenHeight = this.cameras.main.height;
        this.characters = JSON.parse(localStorage.getItem('characters'));
        let character = this.characters[index];
    
        if ((player === 1 && this.player2Index === index) || (player === 2 && this.player1Index === index)) {
            return; 
        }
    
        let x, y;
        let scaleX = 0.7;
        if (player === 1) {
            x = screenWidth / 4;
            y = screenHeight / 2;
        } else {
            x = (screenWidth / 4) * 3;
            y = screenHeight / 2;
            scaleX = -0.7;
        }
    
        if (this[`player${player}Image`]) {
            this[`player${player}Image`].destroy();
        }
    
        if (this[`player${player}Text`]) {
            this[`player${player}Text`].destroy();
        }
        this[`player${player}Image`] = this.add.image(x, y, `${character.name}-preview`).setScale(scaleX, 0.7);
        this[`player${player}Text`] = this.add.text(x, y - 70, character.name, { fontSize: '32px', color: '#ffffff', fontFamily: 'Atlan', align: 'center', fontStyle: 'bold' }).setOrigin(0.5, 5);
    }

    startGame() {
        let playerSelection = {
            player1: this.characters[this.player1Index],
            player2: this.characters[this.player2Index]
        }
        this.registry.set('playersChamps', playerSelection)
        this.scene.stop('SelectChar');
        this.sceneActions.simpleMoveScene('Loading');
    }

    createCharacterSelectionUI() {

        const characterContainer = this.add.container(this.cameras.main.centerX, this.cameras.main.height - 125);
        this.characterSpacing = 170;
    
        this.characters.forEach((character, index) => {
            this.x = (index - (this.characters.length - 1) / 2) * this.characterSpacing;
            const characterImage = this.add.image(this.x, 0, `${character.name}-mini`).setScale(0.15);
            let marcoImage = this.add.image(this.x, 0, 'marco');
            characterContainer.add(characterImage);
            characterContainer.add(marcoImage);
        });
        this.add.existing(characterContainer);
    }
    
    reset() {
        this.player1Index = 0;
        this.player2Index = 1;
        this.player1Selected = false;
        this.player2Selected = false;
        this.characters = [];  
    }

    startTextBlink() {
        this.blinkTimer1 = this.time.addEvent({
            delay: 1000,
            callback: () => {
                this.textPlayer1.visible = !this.textPlayer1.visible;
            },
            loop: true
        });
    
        this.blinkTimer2 = this.time.addEvent({
            delay: 1000,
            callback: () => {
                this.textPlayer2.visible = !this.textPlayer2.visible;
            },
            loop: true
        });
    }

    onKeyPressed(number) {
        if (this[`blinkTimer${number}`]) {
            this[`blinkTimer${number}`].remove();
        }
        this[`textPlayer${number}`].visible = true;
    
        this[`blinkTimer${number}`] = this.time.addEvent({
            delay: 150,
            callback: () => {
                this[`textPlayer${number}`].visible = !this[`textPlayer${number}`].visible;
            },
            loop: true
        });
    
        this.time.delayedCall(2000, () => {
            this[`blinkTimer${number}`].remove();
            this[`textPlayer${number}`].visible = true;
        });
    }
}
