
// La idea es mover aqui todas las variables globales.

class GlobalState {
    constructor() {
        this.music = {
            title: false,
            playing: false,
            volume: 1,
            enable:1
        };
    }

    get(key) {
        return this.music[key];
    }

    set(key, value) {
        this.music[key] = value;
    }

    getMusicState() {
        return { ...this.music };
    }

    setMusicState(newmusic) {
        this.music = { ...this.music, ...newmusic };
    }
}

const globalState = new GlobalState();
export default globalState;
