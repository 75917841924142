import Phaser from 'phaser';
import SceneActions from '../utils/sceneActions';
import AuthRequest from '../services/authService';
import { deleteCookie } from '../services/storeService';

export class Pause extends Phaser.Scene {
    constructor() {
        super({ key: 'Pause' });
        this.sceneActions = new SceneActions(this);
        this.soundService = window.soundService;
        this.authRequest = new AuthRequest(process.env.GET_MAPS_URL);
    }

    create() {
        this.setupScene();
        this.setupMenu();
        this.setupInputs();
    }

    setupScene(){
        this.soundService.addSfx('hover', { volume: window.sfxState.volume });
        this.soundService.addSfx('click', { volume: window.sfxState.volume });
        this.add.image(this.cameras.main.width / 2, this.cameras.main.height / 2 - 350, 'pause').setOrigin(0.5).setScale(0.2);
        this.add.rectangle(0, 0, this.cameras.main.width, this.cameras.main.height, 0x000000, 0.5).setOrigin(0);
    }

    setupMenu() {
        const menu = this.add.container(this.cameras.main.centerX, this.cameras.main.centerY).setDepth(1);
        const menuOptions = [
            { text: 'RESUME', action: () => this.resumeGame(), yOffset: -100 },
            { text: 'BACK TO MENU', action: () => this.backToMenu(), yOffset: 0 },
            { text: 'QUIT GAME', action: () => this.quitGame(), yOffset: 100 }
        ];

        menuOptions.forEach(option => {
            const menuOption = this.add.text(0, option.yOffset, option.text, { 
                fontFamily: 'Rubik', 
                fontSize: '36px', 
                fontStyle: 'bold', 
                align: 'center',
                color: '#ffffff' 
            }).setOrigin(0.5);
            menuOption.setInteractive({ useHandCursor: true });
            menuOption.on('pointerdown', () => {
                this.soundService.playSfx('click');
                option.action();
                
            }).on('pointerover', () => {
                this.soundService.playSfx('hover');
                    menuOption.setScale(1.2)
            }).on('pointerout', () => {
                    menuOption.setScale(1)
            });
    
            menu.add(menuOption);
        });
    }

    setupInputs() {
        this.input.keyboard.on('keydown-ESC', () => {
            this.resumeGame();
        });
    }

    resumeGame() {
        this.scene.resume('Game');
        this.scene.stop();
    }

    backToMenu() {
        this.scene.stop('Game');
        this.scene.stop();
        this.soundService.playSfx('click');
        this.sceneActions.movetoScene('Menu');
    }

    quitGame() {
        deleteCookie('userInfo'); 
        this.authRequest.logout(this.idUser);
        this.scene.get('Game').events.emit('shutdown');
        this.scene.stop('Game');
        this.scene.stop();
        this.soundService.playSfx('click');
        this.sceneActions.movetoScene('Login');
    }
}
