class Temporizador {
    constructor(tiempo, scena) {
        this.tiempo = tiempo;
        this.scene = scena;
        this.init()
    }

    init() {
        this.crearTemporizador()
        this.comenzarTemporizador(this.tiempo);
    }

    crearTemporizador() {
        this.texto = this.scene.add.text(960, 50, this.tiempo, {
            fontFamily: 'Arial', fontSize: 38, color: '#ffffff',
            stroke: '#000000', strokeThickness: 8,
            align: 'center', fontStyle: 'bold'
        }).setOrigin(0.5);
    }
    comenzarTemporizador(tiempo) {
        let tiempoTotal = this.pasarSegundos(tiempo);
        this.tiempoRestante = tiempoTotal;

        let actualizarPantalla = () => {
            let minutos = Math.floor(this.tiempoRestante / 60);
            let segundos = this.tiempoRestante % 60;
            if(this.intervaloId != null)
                this.texto.setText( `${minutos.toString()}:${segundos.toString().padStart(2, '0')}`);
            
            if (this.tiempoRestante === 0) {
                clearInterval(this.intervaloId);
                this.scene.finalizarPartida()
            }
            this.tiempoRestante--;
        };

        this.intervaloId = setInterval(actualizarPantalla, 1000);
    }

    pasarSegundos(tiempo) {
        if (Number.isFinite(tiempo)) return tiempo;
        let timepoSeparado = tiempo.split(':')
        return parseInt(timepoSeparado[0]) * 60 + parseInt(timepoSeparado[1]);
    }
    pause() {
        clearInterval(this.intervaloId);
        this.intervaloId = null;
    }

    renaudar() {
        clearInterval(this.intervaloId);
        this.comenzarTemporizador(this.tiempoRestante)
    }

    desactivar() {
        clearInterval(this.intervaloId);
        this.intervaloId = null;
    }
}
export { Temporizador }