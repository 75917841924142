import Phaser from 'phaser';
import GraphicsService from '../services/graphicsService';
import GameService from '../services/gameService';
import AuthRequest from '../services/authService';

export class Loading extends Phaser.Scene {
    constructor() {
        super({ key: 'Loading' });
        this.graphicsService = new GraphicsService(this);
        this.soundService = window.soundService;
    }

    async preload() {
        this.load.setPath('assets/');
        this.playersChamps = this.registry.get('playersChamps');
        const selectedMap = this.registry.get('SelectMap');
        this.gameService = new GameService(this)
        this.gameService.loadVersusImages(this.playersChamps.player1.name);
        this.gameService.loadVersusImages(this.playersChamps.player2.name);
        this.load.spritesheet('loadingGif', 'images/loading/loading.png', {
            frameWidth: 256,
            frameHeight: 256
        });
        this.load.image('mapLoading', selectedMap.map_versus);
        this.loadMapAssets(selectedMap);
        this.loadCharactersAssets();
        this.loadCartelFinal();
        this.load.on('complete', () => {
            this.cameras.main.fadeOut(1500, 0, 0, 0);
            this.time.delayedCall(5000, () => {
                this.scene.start('Game');
                this.scene.pause()
            });
        });
        this.loadSfxAssets();
    }

    create() {
        this.soundService.stopMusic();
        this.cameras.main.fadeIn(1500, 0, 0, 0);
        this.graphicsService.addGeneralBackground('mapLoading');
        this.add.image(this.cameras.main.centerX, this.cameras.main.centerY, 'vs').setScale(0.83);
        this.add.image(this.cameras.main.width/3.6, this.cameras.main.centerY-350, `${this.playersChamps.player1.name}-versus`).setScale(0.65);
        this.add.image(this.cameras.main.height*1.4, this.cameras.main.centerY+200, `${this.playersChamps.player2.name}-versus`).setScale(0.65).setFlipX(true);
        this.anims.create({
            key: 'playGIF',
            frames: this.anims.generateFrameNumbers('loadingGif', { start: 0, end: 11 }), 
            frameRate: 5,
            repeat: -1
        });
    
        this.add.sprite(120, this.cameras.main.height / 1.15, 'loadingGif').play('playGIF').setScale(0.6);

        this.loadGameOptions();
    }
    
    loadCharactersAssets(){
        let dataPlayers ={}
        for (let player in this.playersChamps) {
            this.loadCharacterAssets(this.playersChamps[player],player == 'player1'?'P1':'P2');
            dataPlayers[player] = {}
            dataPlayers[player]['estadisticas'] = this.playersChamps[player].estadistica
            dataPlayers[player]['id'] = this.playersChamps[player].id
            dataPlayers[player]['nombre'] = this.playersChamps[player].name.toLowerCase()
            dataPlayers[player]['player'] = player
        }
        this.registry.set('SelectCharacters',dataPlayers);
    }

    loadCharacterAssets(character,player) {
        let fases = ['fase1','fase2','fase3'];
        fases.forEach(item => {
            let url = `${character[`${item}Url`]}/`;
            this.load.json(`${character.name.toLowerCase()}-anim-${item}`,`${url}anim.json`);
            this.load.atlas(`${character.name.toLowerCase()}-${item}`,`${url}frames.png`,`${url}atlas.json`);
            this.load.image(`${character.name.toLowerCase()}_${item}`,`${url}icon.png`)
        })
        let imageUrl = `${process.env.PERSONAJES_STORAGE_PATH}${character.name.toLowerCase()}/preview.png`;
        this.load.image(`${character.name.toLowerCase()}-preview`, imageUrl);
    }

    async loadMapAssets(map) {
        this.load.image(map.name.toLowerCase(), map.map_bg);
        this.load.image('map_preview', map.map_preview);
        this.load.audio('map_sound', map.map_sound);
        let authRequest = new AuthRequest(map.map_collider);
        let mapInfo = await authRequest.request(map.map_collider);
        mapInfo['id'] = map.id
        this.registry.set('SelectMap',mapInfo);
    }

    loadCartelFinal(){
        this.load.image('cartel-victoria-P1','/end-game/victoria-p1.png');
        this.load.image('cartel-victoria-P2','/end-game/victoria-p2.png');
        this.load.image('cartel-victoria-empate','/end-game/empate.png');
    }

    loadGameOptions(){
        this.battleTime = localStorage.getItem('battleTime') ? JSON.parse(localStorage.getItem('battleTime')) : { label: '5 minutes', value: '05:00' };
        this.registry.set('battleTime',this.battleTime);
    }

    loadSfxAssets() {
        this.load.audio('golpe', 'sound/game/golpe.mp3');
        this.load.audio('caminar', 'sound/game/caminar.mp3');
        this.load.audio('saltar', 'sound/game/saltar.mp3');
        this.load.audio('herido', 'sound/game/herido.mp3');
    }
}
