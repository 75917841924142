class GraphicsService {
    constructor(scene) {
        this.scene = scene;
    }

    createGraphics(x, y, width, height, color,brightness) {
        const graphics = this.scene.add.graphics();
        graphics.fillStyle(color, brightness);
        graphics.fillRect(x, y, width, height);
        return graphics;
    }

    getRectangleBounds(image) {
        const rectX = image.x - (image.displayWidth / 2);
        const rectY = image.y - (image.displayHeight / 2);
        const rectWidth = image.displayWidth;
        const rectHeight = image.displayHeight;

        return { x: rectX, y: rectY, width: rectWidth, height: rectHeight };
    }

    getImageCenter(image) {
        return { centerX: image.x, centerY: image.y };
    }

    transitionFadeScene() {
        const screenHeight = this.scene.cameras.main.height;
        const screenWidth = this.scene.cameras.main.width;
        const fadeOverlay = this.scene.add.rectangle(screenWidth / 2, screenHeight / 2, screenWidth, screenHeight, 0x000000);
        fadeOverlay.setAlpha(0);

        this.scene.tweens.add({
            targets: fadeOverlay,
            alpha: 1, 
            duration: 1000, 
            ease: 'Linear', 
        });
    }

    addGeneralBackground(nameBackgroundImage) {
        const background = this.scene.add.image(0, 0, nameBackgroundImage).setOrigin(0);
        const scaleX = this.scene.cameras.main.width / background.width;
        const scaleY = this.scene.cameras.main.height / background.height;
        const scale = Math.max(scaleX, scaleY); 
        background.setScale(scale)
    }

    addMenuBackground(nameBackgroundImage){
        const background = this.scene.add.image(0, -123, nameBackgroundImage).setOrigin(0);
        const scaleX = this.scene.cameras.main.width / background.width;
        const scaleY = this.scene.cameras.main.height  / background.height ;
        const scale = Math.max(scaleX, scaleY); 
        background.setScale(scale)
    }
}

export default GraphicsService;
