import Phaser from "phaser";
import { Controles } from '../utils/controles.js'
const IZQUIERDA = 0;
const DERECHA = 1;
const ATAQUE_1 = 1;
const ATAQUE_2 = 1.3;
const ATAQUE_3 = 1.5;
const FASE_1 = 1;
const FASE_2 = 2;
const FASE_3 = 3;
const KILLS_FASE_2 = 5 
const KILLS_FASE_3 = 10 
const SALTO = 10
const ACELERACION = 700
const DRAGX = 250
const DRAGY = 200
const SALTOS = 2
class Player extends Phaser.Physics.Arcade.Sprite {
  constructor(scene, options) {
    
    let { x, y } = options;
    super(scene, x, y, options.nombre);
    this.respawn = {
      'x':x,
      'y':y
    }
    this.id = options.id;
    this.name = options.nombre;
    this.player = options.player == 'player1' ? 'P1':'P2';
    this.options = options;
    this.scene = scene;
    this.salto = 0;
    this.estadoAire = true;
    this.fase = FASE_1;
    this.coliderAtaque = false;
    this.flipX = this.player === 'P1' ? false : true;
    this.deslizamiento = 0
    this.aumento = ATAQUE_1;
    this.animaciones ={
      atacar: ()=> 'atacar-'+options.nombre+'-'+this.fase,
      saltar:()=>'saltar-'+options.nombre+'-'+this.fase,
      patada:()=>'patada-'+options.nombre+'-'+this.fase,
      caminar:()=>'caminar-'+options.nombre+'-'+this.fase,
      deslizarse:()=>'deslizarse-'+options.nombre+'-'+this.fase,
      parado:()=>'parado-'+options.nombre+'-'+this.fase,
      caer:()=>'caer-'+options.nombre+'-'+this.fase,
      caminarAtacando:()=>'caminar_atacando-'+options.nombre+'-'+this.fase,
      herido:()=>'herido-'+options.nombre+'-'+this.fase,
      muerto:()=>'muerto-'+options.nombre+'-'+this.fase,
    }
    this.sonido = {
      atacar:this.scene.sound.add('golpe', { volume: window.sfxState.volume, loop: false }),
      caminar:this.scene.sound.add('caminar', { volume: window.sfxState.volume, loop: true }),
      saltar:this.scene.sound.add('saltar', { volume: window.sfxState.volume, loop: false }),
      herido:this.scene.sound.add('herido', { volume: window.sfxState.volume, loop: false }),
    }
    this.estadisticas = {
      vida:  options.estadisticas.vida ,
      ataque: () => options.estadisticas.ataque * this.aumento ,
      patada: () => options.estadisticas.patada * this.aumento ,
      velocidad: () =>options.estadisticas.velocidad * this.aumento ,
      salto: () => options.estadisticas.salto * this.aumento * SALTO ,
    }
    //Crea los controles para cada personaje
    this.controles = new Controles(scene, this.player);
    //Devuelve un objeto que al pulsar las teclas devuelve si estan pulsadas o no
    this.movimientos = this.controles.getControles();
    //Devuelve un objeto con las teclas que se han de pulsar para realizar las acciones
    this.teclas = this.controles.controles;
    this.init();
    this.caer();
    // Crea el hud
    this.scene.hud.add(options.nombre,this.id);
    this.sonidoIsPlaying = false;
  }

  init() {
    this.crearSprite();
    this.crearAnimaciones(); 
    this.eventos();
  }

  eventos() {
    this.scene[this.teclas.down].on('up', () => {
      this.parado();
      this.deslizamiento = 0;
    });
    this.scene[this.teclas.attack].on('down', () => {
      if(!this.estaDeslizandose()) {
        this.atacar();
        
      }
    });

    this.scene[this.teclas.kick].on('down', () => {
      if(!this.estaDeslizandose() && !this.estaCaminando() && !this.estaCaminandoAtacando()) this.patada();
    });
    this.scene[this.teclas.left].on('up', () => {
      if(this.body.velocity.x == 0 && !this.estaCayendo()) this.parado();
    });
    this.scene[this.teclas.right].on('up', () => {
      if(this.body.velocity.x == 0 && !this.estaCayendo()) this.parado();
    });
    this.scene[this.teclas.up].on('down', () => {
      this.saltar();
      
    });
    
  }

  crearSprite() {
    this.scene.sys.updateList.add(this);
    this.scene.sys.displayList.add(this);
    this.scene.physics.world.enableBody(this);
    this.setScale(1.3);
    this.body.setSize(50, 115,true);
    this.body.setOffset(75, 50,true);
    this.cabeza = this.scene.add.circle(this.x, this.y - 60, 30, 0x6666ff, 0);
    this.cabeza.personaje = this;
    this.scene.physics.add.existing(this.cabeza);
    this.cabeza.body.allowGravity = false;
    this.cabeza.body.enable = true
    this.cabeza.body.immovable = true;
    this.body.collideWorldBounds = true;
    this.body.enable = true;
    this.cabeza.body.setCircle(55); 
    this.setPosition(this.x, this.y);
  }
  crearHitbox(){
    this.crearHitboxSpada();
    this.crearHitboxPatada();
  }

  crearHitboxSpada(){
    this.espadaHitbox = this.scene.add.rectangle(this.x, this.y, 90, 100, 0x6666ff,0);
    this.espadaHitbox.personaje = this;
    this.scene.physics.add.existing(this.espadaHitbox);
    this.espadaHitbox.body.allowGravity = false;
    this.espadaHitbox.body.enable = false;
    this.scene.physics.world.remove(this.espadaHitbox.body);
    this.scene.personajes.forEach(element => {
      if(element.id !== this.id){
        this.scene.physics.add.overlap(this.espadaHitbox, element, this.atacarEnemigoHit, undefined, this.scene);
        this.scene.physics.add.overlap(this.espadaHitbox, element.cabeza, this.atacarEnemigoHit, undefined, this.scene);
      }
    });

  }
  crearHitboxPatada(){
    this.patadaHitbox = this.scene.add.rectangle(this.x, this.y, 30, 50, 0x6666ff,0);
    this.patadaHitbox.personaje = this;
    this.scene.physics.add.existing(this.patadaHitbox);
    this.patadaHitbox.body.allowGravity = false;
    this.patadaHitbox.body.enable = false;
    this.scene.physics.world.remove(this.patadaHitbox.body);
    this.scene.personajes.forEach(element => {
      if(element.id !== this.id){
        this.scene.physics.add.overlap(this.patadaHitbox, element, this.patadaEnemigoHit, undefined, this.scene);
        this.scene.physics.add.overlap(this.patadaHitbox, element.cabeza, this.patadaEnemigoHit, undefined, this.scene);
      }
    });
  }

  hitAtaque(){
    const starHit = (anim,frame)=>{
      this.off(Phaser.Animations.Events.ANIMATION_UPDATE,starHit);
      this.espadaHitbox.setPosition(
        this.flipX ? this.x - 80 : this.x + 80,
        this.y + 35
      );
      this.scene.physics.world.add(this.espadaHitbox.body);
      this.espadaHitbox.body.enable = true;

    }
    this.on(Phaser.Animations.Events.ANIMATION_UPDATE, starHit);
    this.on(Phaser.Animations.Events.ANIMATION_COMPLETE, (anim) => {
      if(anim.key.startsWith(this.animaciones.atacar())) {
      this.eliminarHitboxAtaque();
      }
    });
  }
  hitPatada(){
    const starHit = (anim,frame)=>{
      this.off(Phaser.Animations.Events.ANIMATION_UPDATE,starHit);
      this.patadaHitbox.setPosition(
        this.flipX ? this.x - 30 : this.x + 30,
        this.y + 75
      );
      this.scene.physics.world.add(this.patadaHitbox.body);
      this.patadaHitbox.body.enable = true;
    }
    this.on(Phaser.Animations.Events.ANIMATION_UPDATE, starHit);
    this.on(Phaser.Animations.Events.ANIMATION_COMPLETE, (anim) => {
      if(anim.key === this.animaciones.patada()) {
      this.eliminarHitboxPatada();
      }
    });
  }

  atacarEnemigoHit(obj1,obj2){
    if(obj1.constructor.name !== 'Player') obj1 = obj1.personaje;
    if(obj1.coliderAtaque) return;
    obj1.coliderAtaque = true;
    if(obj2.constructor.name !== 'Player') {
      if(obj2.personaje) {
        obj2 = obj2.personaje
      }
    }
    if( obj2?.anims?.currentAnim?.key == obj2?.animaciones?.muerto()) return
    obj2.estadisticas.vida -= obj2.estadisticas.vida > 0 ? obj1.estadisticas.ataque(): 0;
    obj2.herido();
    this.estadisticas.addDanio(obj1,obj1.estadisticas.ataque());
    if(obj2.estaMuerto()){
      this.estadisticas.addKill(obj1);
      this.estadisticas.addMuerte(obj2);
      let killsObj1 = this.estadisticas.getKills(obj1)
      if(killsObj1 == KILLS_FASE_2) obj1.setFase(FASE_2);
      if(killsObj1 == KILLS_FASE_3) obj1.setFase(FASE_3);
      this.hud.setKill(killsObj1,obj1.id);
      obj1.resetVida();
      obj2.muerto();
    }
  }
  patadaEnemigoHit(obj1,obj2){
    if(obj1.constructor.name !== 'Player') obj1 = obj1.personaje;
    if(obj1.coliderAtaque) return;
    obj1.coliderAtaque = true;
    if(obj2.constructor.name !== 'Player') {
      if(obj2.personaje) {
        obj2 = obj2.personaje
      }
    }
    if(obj2.anims.currentAnim.key == obj2.animaciones.muerto()) return
    obj2.estadisticas.vida -= obj2.estadisticas.vida > 0 ? obj1.estadisticas.patada(): 0;
    obj2.herido();
    obj1.golpes ++;
    this.estadisticas.addDanio(obj1,obj1.estadisticas.ataque());
    if(obj2.estaMuerto()){
      let killsObj1 = this.estadisticas.getKills(obj1)
      let killsObj2 = this.estadisticas.getKills(obj2)
      if(killsObj1 == KILLS_FASE_2) obj1.setFase(FASE_2);
      if(killsObj1 == KILLS_FASE_3) obj1.setFase(FASE_3);
      this.estadisticas.addKill(obj1);
      this.estadisticas.addMuerte(obj2);
      
      this.hud.setKill(killsObj1,obj1.id);
      obj1.resetVida();
      obj2.muerto();
    }
  }
  crearAnimaciones() {
    ['fase1','fase2','fase3'].forEach((fase) => {
      let personajeAnim = this.scene.cache.json.get( `${this.name}-anim-${fase}`); 
      this.scene.anims.fromJSON(personajeAnim);
    });
    this.on(Phaser.Animations.Events.ANIMATION_COMPLETE, (anim, frame) => {
      let animaciones = [this.animaciones.atacar(), this.animaciones.saltar(),this.animaciones.patada()];
      
          if (animaciones.includes(anim.key) ) {
              this.anims.play(this.animaciones.parado());
          }
          //Cuando termine de atacar que vuelva a caminar
          if(anim.key.startsWith(this.animaciones.caminarAtacando())){
              this.anims.play(this.animaciones.caminar());   
          }

          if(anim.key === this.animaciones.muerto()){
            this.personajeEliminado();
          }
          if(anim.key === this.animaciones.herido()){
            this.anims.play(this.animaciones.parado()); 
          }
      });
  }

  saltar() {
    this.actualizarHitboxCabeza('saltar');
    if(this.salto < SALTOS){
      this.anims.play(this.animaciones.saltar());
      this.setVelocityY(-this.estadisticas.salto());
      this.salto++;
      this.estadoAire = true;
      this.sonido.saltar.play();
    }
  }

  desplazar(direccion) {
    if(this.estaHerido()){
      return
    } 
    if(this.estaMuertoAnim()){
      this.setVelocityX(0);
      this.setAccelerationX(0);
      return
    }
    
    if(!this.estaAtacando()){
      this.eliminarHitboxAtaque();
      this.eliminarHitboxPatada();  
    } 
    this.actualizarHitboxCabeza('desplazarse');
    let cambioDireccion = !this.flipX == (direccion === IZQUIERDA);
    this.flipX = direccion === IZQUIERDA;
    if(this.movimientos.down()){
      this.deslizarse();
    }else if (this.movimientos.attack()) {
      this.anims.play(this.animaciones.atacar());
    } else if (this.estaAtacando()) {
      let animFrame = this.anims.currentFrame.index;
      let nombreAnim = this.animaciones.caminarAtacando() + animFrame
      try {
        this.crearAnimacionDesdeFrame(this.animaciones.caminarAtacando(), nombreAnim, animFrame);
        this.anims.play(nombreAnim, true); // Reproducir la animación 'caminar'
      } catch (error) {}
      
    }else if(this.estaCayendo()){
      
    }
    else {
      if (!this.sonidoIsPlaying) {
        this.sonido.caminar.play();
        this.sonidoIsPlaying = true
      }
      
      if (!this.estaCaminando() && !this.estaCaminandoAtacando() && !this.estaDeslizandose() && !this.estaHerido()) {
        this.anims.play(this.animaciones.caminar());
      }
    }
    // let velocidad = this.estadisticas.velocidad();
    

    if(cambioDireccion) {
      this.setVelocityX(0);
    }

    if(this.body.velocity.x == 0){
      this.setAccelerationX((direccion === IZQUIERDA ? -ACELERACION : ACELERACION));
    }
  }

  atacar(){
    if(!this.estaAtacando()){
      this.eliminarHitboxPatada();
      this.anims.play(this.animaciones.atacar());
      this.hitAtaque();
      this.sonido.atacar.play();
    } 
  }
  patada(){
    if(!this.estaPateando()) {
      this.eliminarHitboxAtaque();
      this.anims.play(this.animaciones.patada());
      this.hitPatada();
    }
  }
  parado(){
    this.actualizarHitboxCabeza('parado');
    if(!this.estaParado() && this.puedePararse()){
      this.anims.play(this.animaciones.parado()); 
    }
  }
  caer(){
    this.actualizarHitboxCabeza('caer');
    if(!this.estaCayendoAnim() && !this.estaAtacando() && !this.estaCaminandoAtacando()){
      this.anims.play(this.animaciones.caer());
    }
  }
  deslizarse(){
    this.actualizarHitboxCabeza('deslizarse');
    if(!this.estaDeslizandose() && this.deslizamiento <= 15){
      this.anims.play(this.animaciones.deslizarse());
    } 
    if(this.deslizamiento >= 100 ) {
      this.parado();
    }
    this.deslizamiento ++;
  }
  
  herido(){
    let vida = Math.round(parseInt(this.estadisticas.vida)/10);
    vida = vida <= 0 ? 1 : vida;
    this.scene.hud.setVida(vida,this.id);
    this.anims.play(this.animaciones.herido());
    this.sonido.herido.play();
  }
  muerto(){
    if(this.anims.currentAnim.key === this.animaciones.muerto()) return;
    this.anims.play(this.animaciones.muerto());
  }

  estaCayendo() {
    return this.body.velocity.y > 0;
  } 
  tocandoSuelo(){
    return this.body.touching.down;
  }
  resetSalto(){
    this.salto = 0;
  }
  
  estaDeslizandose(){
    return this.anims.currentAnim ? 
      this.anims.currentAnim.key === this.animaciones.deslizarse() : false;
  }
  estaCaminandoAtacando(){
    return this.anims.currentAnim ? 
      this.anims.currentAnim.key.startsWith(this.animaciones.caminarAtacando()): false;
  }
  estaAtacando(){
    return this.anims.currentAnim ? 
      this.anims.currentAnim.key === this.animaciones.atacar(): false;
  }
  estaCaminando(){
    return this.anims.currentAnim ? 
      this.anims.currentAnim.key === this.animaciones.caminar(): false;
  }
  estaParado(){
    return this.anims.currentAnim ? 
      this.anims.currentAnim.key === this.animaciones.parado(): false;
  }
  estaPateando(){
    return this.anims.currentAnim ? 
      this.anims.currentAnim.key === this.animaciones.patada(): false;
  }
  estaCayendoAnim(){
    return this.anims.currentAnim ? 
      this.anims.currentAnim.key === this.animaciones.caer() : false;
  }
  estaMuertoAnim(){
    return this.anims.currentAnim ? 
      this.anims.currentAnim.key === this.animaciones.muerto() : false;
  }
  estaMuerto(){
    return Math.round(parseInt(this.estadisticas.vida)/10) <= 1;
  }
  puedePararse(){
    let animaciones = [this.animaciones.saltar(), this.animaciones.atacar(), this.animaciones.patada(),this.animaciones.herido(),this.animaciones.muerto()];
    return this.anims.currentAnim ? !animaciones.includes(this.anims.currentAnim.key) : false;
  }
  estaHerido(){
    return this.anims.currentAnim ? 
      this.anims.currentAnim.key === this.animaciones.herido(): false;
  }

  eliminarHitboxAtaque(){
    this.scene.physics.world.remove(this.espadaHitbox.body);
    this.espadaHitbox.body.enable = false;
    this.coliderAtaque = false;
  }
  eliminarHitboxPatada(){
    this.scene.physics.world.remove(this.patadaHitbox.body);
    this.patadaHitbox.body.enable = false;
    this.coliderAtaque = false;
  }
  

  update(){
    if(this.tocandoSuelo() && this.estadoAire){
        this.resetSalto();
        this.estadoAire = false;
        if(this.body.velocity.x != 0) this.sonido.caminar.play()

    }
    if(this.estadoAire) this.sonido.caminar.stop();
    if(this.estaCayendo()){
      if(!this.estaAtacando() && !this.estaDeslizandose()&& !this.estaPateando() && !this.estaHerido()) this.caer();
    }
    if ( this.y > this.scene.alto){
      let player2 = this.scene.personajes.find(item => item.id != this.id);
      this.scene.estadisticas.addKill(player2);
      this.scene.estadisticas.addMuerte(this);
      this.scene.hud.setKill(this.scene.estadisticas.getKills(player2),player2.id);
      this.personajeEliminado();
    }
    this.setMaxVelocity(200,400)
    this.comprobarFase()
    if(this.movimientos.right()){
      this.desplazar(DERECHA);
    }else if(this.movimientos.left()){
      this.desplazar(IZQUIERDA);
    }
    else{
      if(this.body.velocity.x == 0 && !this.estaCayendo()){
        this.parado();
        this.sonido.caminar.stop();
        this.sonidoIsPlaying = false
      } 
      else if (this.body.velocity.x != 0 && !this.estaCaminando() && !this.estaAtacando() && !this.estaHerido() && !this.estaMuerto()){
        this.anims.play(this.animaciones.caminar());
      }
      this.setDrag(DRAGX,DRAGY)
      this.setAccelerationX(0)
    }

  }

  personajeEliminado(){
    this.x = this.respawn.x
    this.y = this.respawn.y
    this.resetVida();
  }


  /**
     * Crea una animacion nueva a partir de una animacion ya existente.
     * En caso de que la animacion ya exista no se creara.
     * @param {String} animacionOriginal Nombre de la animacion original
     * @param {String} nuevaAnimacion Nombre de la nueva animacion
     * @param {Number} frameInicio Frame en el que empieza la nueva animacion
     * @returns {void}
     * 
  */
  crearAnimacionDesdeFrame(animacionOriginal, nuevaAnimacion, frameInicio) {
    if(this.scene.anims.anims.entries[nuevaAnimacion]){
        return;
    }
    /**cojo los frames de la animacion original y los partos para conger solo 
    * los frame de inicio que se me pasa por parametro hasta el final
    * Despues creo la animacion y le introduzco uno a uno los frames.Esto lo hago por que si igualo
    * los dos arrays se me quedan enlazados entonces al terminar la funcion se me borran los frames 
    * de la animacion nueva y si crear sin frames la animacion nueva 
    */
    let datosAnimacion = this.scene.anims.anims.entries[animacionOriginal].frames;
    datosAnimacion = datosAnimacion.slice(frameInicio);
    this.scene.anims.create({ key: nuevaAnimacion, frames: datosAnimacion, frameRate: 20, repeat: 0 });
    let animacionNueva = this.scene.anims.anims.entries[nuevaAnimacion];
    datosAnimacion.forEach((frame) => {
        animacionNueva.frames.push(frame);
    });
  }
  actualizarHitboxCabeza(animacion = 'parado'){
    let acciones ={
      parado: () => this.cabeza.setPosition(
                      this.flipX ? this.x - 25 : this.x - 35, 
                      this.y - 42
                    ),
      caer: () => this.cabeza.setPosition(
                      this.flipX ? this.x -30 : this.x - 15, 
                      this.y - 50
                    ),
      deslizarse: () => this.cabeza.setPosition(
                      this.flipX ? this.x + 10 : this.x - 60, 
                      this.y - 10
                    ),
      saltar: () => this.cabeza.setPosition(
                      this.flipX ? this.x - 20 : this.x - 20, 
                      this.y - 50
                    ),
      desplazarse: () => this.cabeza.setPosition(
                      this.flipX ? this.x - 45 : this.x - 10, 
                      this.y - 42
                    )
    }
    acciones[animacion]()
  }
  
  resetVida(){
    this.estadisticas.vida = 120;
    this.scene.hud.setVida('12',this.id);
  }

  asignarColision(personajes){
    personajes.forEach(personaje =>{
      if(personaje.id == this.id) return;
      this.scene.physics.add.collider(personaje, this);
      this.scene.physics.add.collider(personaje.cabeza, this.cabeza);
    })
  }

  setFase(fase){
      this.aumento = fase == FASE_2 ? ATAQUE_2: ATAQUE_3;
      this.fase = fase;
      this.scene.hud.setFase(fase,this.id);
  }  
  comprobarFase(){
    let killsObj1 = this.scene.estadisticas.getKills(this)
    if(killsObj1 == KILLS_FASE_2) this.setFase(FASE_2);
    if(killsObj1 == KILLS_FASE_3) this.setFase(FASE_3);
  } 
}



export { Player };