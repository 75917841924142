class SoundService {
    constructor() {
        this.scene = null;
        this.music = null;
        this.sfx = {}
        this.musicPausedPosition = 0;
    }

    setScene(scene) {
        this.scene = scene;
    }

    loadMusic(title) {
        try {
            if (!this.scene || !this.scene.sound) {
                throw new Error('Sound System Unavailable');
            }

            if (window.musicState.playing && window.musicState.title === title) {
                return;
            }

            this.stopMusic();
            this.music = this.scene.sound.add(title, { volume: window.musicState.volume, loop: true });
            this.music.play();
            window.musicState = {
                title: title,
                playing: true,
                volume: window.musicState.volume
            };

            this.playMusic();
        } catch (error) {
            console.error('Music Load Error', error.message);
        }
    }

    playMusic() {
        if (this.music) {
            window.musicState.playing = true;
            this.music.play({ seek: this.musicPausedPosition });
        }
    }

    modifyVolume(volume) {
        window.musicState.volume = volume / 100;
        if (this.music) {
            this.music.setVolume(window.musicState.volume);
        }
        if (volume === 0) {
            this.music.pause(); 
        } else {
            this.music.resume(); 
        }
    }

    pauseMusic() { 
        if (this.music && this.music.isPlaying) {
            this.musicPausedPosition = this.music.seek; 
            this.music.pause();
        }
    }

    stopMusic() {
        if (this.music) {
            window.musicState.playing = false;
            this.music.stop();
            this.music.destroy(); 
            this.music = null; 
        }
    }

    playingMusic() {
        return window.musicState.playing;
    }

    addSfx(key, config) {
        if (this.scene) {
            this.sfx[key] = this.scene.sound.add(key, config);
        }
    }

    playSfx(key) {
        if (this.sfx[key] && window.sfxState.enable) {
            this.sfx[key].play({ volume: window.sfxState.volume });
        }
    }

    stopSfx(key) {
        if (this.sfx[key]) {
            this.sfx[key].stop();
        }
    }

    modifySfxVolume(volume) {
        window.sfxState.volume = volume / 100;
        for (let key in this.sfx) {
            if (this.sfx[key]) {
                this.sfx[key].setVolume(window.sfxState.volume);
            }
        }
    }
    
    stopAllSfx() {
        for (let key in this.sfx) {
            if (this.sfx[key]) {
                this.sfx[key].stop();
            }
        }
    }

    applyGlobalSound({ title, playing, volume, sfxVolume, sfxEnable } = {}) {
        window.musicState = {
            ...window.musicState,
            ...(title !== undefined && { title }),
                ...(playing !== undefined && { playing }),
                ...(volume !== undefined && { volume })
        };

        window.sfxState = {
            ...window.sfxState,
            ...(sfxVolume !== undefined && { volume: sfxVolume }),
            ...(sfxEnable !== undefined && { enable: sfxEnable })
        };
    }
}

export default SoundService;
