class GameService {
    constructor(scene) {
        this.scene = scene;
    }

    async loadData(authRequest, url, nameData, tipoDato) {
        try {
            const res = await authRequest.request(url);
            this.saveData(nameData, res[tipoDato]);
            return res[tipoDato];
        } catch (error) {
            return null;
        }
    }

    saveData(nameData, data) {
        localStorage.setItem(nameData, JSON.stringify(data));
    }

    loadCharacterImages() {
        const characters = JSON.parse(localStorage.getItem('characters'));
        if (characters) {
            characters.forEach((character) => {
                const imageUrl = `${process.env.PERSONAJES_STORAGE_PATH}${character.name.toLowerCase()}/preview.png`;
                const miniImage = `${process.env.PERSONAJES_STORAGE_PATH}${character.name.toLowerCase()}/fase3/icon.png`;
                this.scene.load.image(`${character.name}-preview`, imageUrl);
                this.scene.load.image(`${character.name}-mini`, miniImage);
            });
            this.scene.load.start();
        } else {
            console.error('Error: Characters has not been found');
        }
    }

    loadVersusImages(character) {
        const characters = JSON.parse(localStorage.getItem('characters'));
        const selectedCharacter = characters.find((char) => character === char.name) || null
        if (selectedCharacter) {
            const versus = `${process.env.PERSONAJES_STORAGE_PATH}${selectedCharacter.name.toLowerCase()}/versus.png`;
            this.scene.load.image(`${selectedCharacter.name}-versus`, versus);
        } else {
            console.error('Error: Characters has not been found');
        }
    }

    loadMapsImages() {
        const maps = JSON.parse(localStorage.getItem('maps'));
        if (maps) {
            maps.forEach((map) => {
                const imageUrl = `${process.env.MAPAS_STORAGE_PATH}${map.name.toLowerCase()}/preview.png`;
                this.scene.load.image(`${character.name}-preview`, imageUrl);
            })
            this.scene.load.start();
        } else {
            console.error('Error: Map has not been found');
        }
    }

    async loadPlayerHistory(authRequest, endpoint) {
        try {
            const userHistory = await authRequest.request(endpoint) || {};
    
            // Default values if the properties are missing or undefined
            const stats = {
                totalDamage: userHistory.total_damage || 0,
                totalKills: userHistory.total_kills || 0,
                totalDies: userHistory.total_dies || 0,
                largestKillSpree: userHistory.largest_kill_spree || 0,
                mostUsedMap: userHistory.most_used_mapa?.name || 'No Games Found',
                favoriteChampion: userHistory.most_used_personaje?.name || 'No Games Found',
            };
            return stats;
        } catch (error) {
            console.error('Error fetching user history:', error);
    
            return {
                totalDamage: 0,
                totalKills: 0,
                totalDies: 0,
                largestKillSpree: 0,
                mostUsedMap: 'No Games Found',
                favoriteChampion: 'No Games Found',
            };
        }
    }

    loadDefaultControls() {
        if (localStorage.getItem('controlsP1') == null)
            localStorage.setItem('controlsP1', JSON.stringify({ up: 'ARROWUP', down: 'ARROWDOWN', left: 'ARROWLEFT', right: 'ARROWRIGHT', attack: 'N', kick: 'M' }));
        if (localStorage.getItem('controlsP2') == null)
            localStorage.setItem('controlsP2', JSON.stringify({ up: 'W', down: 'S', left: 'A', right: 'D', attack: 'G', kick: 'H' }));
    }
}

export default GameService;