import Phaser, { Scene } from 'phaser';
import GraphicsService from '../services/graphicsService';
import SceneActions from '../utils/sceneActions.js';

export class Settings extends Phaser.Scene {
    constructor() {
        super({ key: 'Settings' });
        this.graphicsService = new GraphicsService(this);
        this.soundService = window.soundService;
        this.sceneActions = new SceneActions(this);
        this.unsavedChanges = false;

        this.music = localStorage.getItem('audioMusic') ? JSON.parse(localStorage.getItem('audioMusic')) : { enable: 1, volume: 60 };
        this.fxsound = localStorage.getItem('audioFXSound') ? JSON.parse(localStorage.getItem('audioFXSound')) : { enable: 1, volume: 60 };

        this.resolution = localStorage.getItem('gameResolution') ? JSON.parse(localStorage.getItem('gameResolution')) : { width: 1920, height: 1080 };
        this.battleTime = localStorage.getItem('battleTime') ? JSON.parse(localStorage.getItem('battleTime')) : { label: '5 minutes', value: '05:00' };
        
        this.battleTimePosition = localStorage.getItem('battleTimePosition') ? JSON.parse(localStorage.getItem('battleTimePosition')) : 0
        this.controlsp1 = localStorage.getItem('controlsP1') ? JSON.parse(localStorage.getItem('controlsP1')) : { up: 'UP', down: 'DOWN', left: 'LEFT', right: 'RIGHT', attack: 'N', kick: 'M' };
        this.controlsp2 = localStorage.getItem('controlsP2') ? JSON.parse(localStorage.getItem('controlsP2')) : { up: 'W', down: 'S', left: 'A', right: 'D', attack: 'G', kick: 'H' };
        this.generalContainer = null;
        this.audioContainer = null;
        this.gameContainer = null;
        this.videoContainer = null;
        this.controlsContainer = null;
    }

    preload() {
        this.load.setPath('assets/');
        this.load.image('title', 'images/settings/title.png');
        this.load.image('audiounselect', 'images/settings/unselected/audioa.png');
        this.load.image('gameunselect', 'images/settings/unselected/gamea.png');
        this.load.image('videounselect', 'images/settings/unselected/videoa.png');
        this.load.image('controlsunselect', 'images/settings/unselected/controlsa.png');
        this.load.image('audioselect', 'images/settings/selected/audiob.png');
        this.load.image('gameselect', 'images/settings/selected/gameb.png');
        this.load.image('videoselect', 'images/settings/selected/videob.png');
        this.load.image('controlsselect', 'images/settings/selected/controlsb.png');
        this.load.image('p1', 'images/champion-select/arrows/player1.png');
        this.load.image('p2', 'images/champion-select/arrows/player2.png');
        this.load.image('soundon', 'images/settings/music/soundon.png');
        this.load.image('soundoff', 'images/settings/music/soundoff.png');
        this.load.image('leftArrow','images/champion-select/arrows/1x/back.png')
        this.load.image('rightArrow', 'images/champion-select/arrows/1x/next.png')
    }

    create() {
        this.titleSectionStyle = {
            fontFamily: 'Rubik',
            fontSize: '52px',
            fontStyle: 'bold',
            color: '#ffffff',
            align: 'center',
            stroke: '#000000',
            strokeThickness: 4
        };

        this.titleOptionStyle = {
            fontFamily: 'Rubik',
            fontSize: '36px',
            fontStyle: 'bold',
            color: '#ffffff',
            align: 'left',
            stroke: '#000000',
            strokeThickness: 3
        };

        this.controlsOptionStyle = {
            fontFamily: 'Rubik',
            fontSize: '24px',
            fontStyle: 'bold',
            color: '#000000',
            align: 'left'
        };

        this.controlsKeyStyle = {
            fontFamily: 'Rubik',
            fontSize: '24px',
            fontStyle: 'bold',
            color: '#FFFFFF',
            align: 'center'
        };

        this.messagePopUp = {
            fontSize: '28px',
            color: '#FFFFFF',
            align: 'center',
            fontStyle: 'bold',
            backgroundColor: '#000000', 
            padding: { left: 20, right: 20, top: 10, bottom: 10 },
        };

        this.cameras.main.fadeIn(1500, 0, 0, 0);
        this.audioContainer = this.add.container(1450, 300).setVisible(false);
        this.gameContainer = this.add.container(1450, 300).setVisible(false);
        this.videoContainer = this.add.container(1450, 300).setVisible(false);
        this.controlsContainer = this.add.container(1450, 300).setVisible(false);

        const containers = [this.audioContainer, this.gameContainer, this.videoContainer, this.controlsContainer];
        containers.forEach(container => {
            container.setDepth(10);
        });

        this.graphicsService.addGeneralBackground('blueSky');

        this.add.image(150, 50, 'title').setOrigin(0);
        this.add.image(this.cameras.main.centerX * 0.13, this.cameras.main.centerY * 1.9,'return');

        this.setupInput();
        
        const buttonsConfig = [
            { unselected: 'audiounselect', selected: 'audioselect', action: () => this.showSection('audio'), x: 480, y: 430 },
            { unselected: 'gameunselect', selected: 'gameselect', action: () => this.showSection('game'), x: 480, y: 530 },
            { unselected: 'videounselect', selected: 'videoselect', action: () => this.showSection('video'), x: 480, y: 630 },
            { unselected: 'controlsunselect', selected: 'controlsselect', action: () => this.showSection('controls'), x: 480, y: 730 }
        ];

        let selectedButton = null;
        let defaultButtonConfig = buttonsConfig[1];

        buttonsConfig.forEach(buttonConfig => {
            const button = this.add.image(buttonConfig.x, buttonConfig.y, buttonConfig.unselected)
            .setInteractive({ useHandCursor: true })
            .setOrigin(0.5)
            .on('pointerover', () => {
                if(button !== selectedButton){
                    button.setTexture(buttonConfig.selected);
                    this.soundService.playSfx('hover');
                    button.setScale(1.1);
                }
            })
            .on('pointerout', () => {
                if(button !== selectedButton){
                    button.setTexture(buttonConfig.unselected);
                    button.setScale(0.25);
                }
            })
            .on('pointerdown', () => {
                if(selectedButton){
                    selectedButton.setTexture(selectedButton.buttonConfig.unselected);
                    selectedButton.setScale(0.25);
                }

                if(button !== selectedButton){
                    this.soundService.playSfx('click');
                }

                button.setTexture(buttonConfig.selected);
                button.setScale(1.1);
                selectedButton = button;
                selectedButton.buttonConfig = buttonConfig;
                buttonConfig.action(); 
            });

            button.setScale(0.25);

            if(buttonConfig === defaultButtonConfig){
                button.setTexture(buttonConfig.selected);
                button.setScale(1.1);
                selectedButton = button;
                selectedButton.buttonConfig = buttonConfig;
                buttonConfig.action(); 
            }
        });

        this.initAudioSection();
        this.initGameSection();
        this.initVideoSection();
        this.initControlsSection();
        defaultButtonConfig.action();
    }

    setupInput() {

        const keyActions = {
            ESCAPE: () => this.exitToMenu()
        }
        this.input.keyboard.on('keydown', (event) => {
            const action = keyActions[event.key.toUpperCase()];
            if (action) {
                action();
            }
        });
    }

    showSection(sectionName) {
        this.audioContainer.setVisible(sectionName === 'audio');
        this.gameContainer.setVisible(sectionName === 'game');
        this.videoContainer.setVisible(sectionName === 'video');
        this.controlsContainer.setVisible(sectionName === 'controls');
    }

    initAudioSection() {
        this.audioContainer.add(this.add.text(0, 0, 'AUDIO OPTIONS', this.titleSectionStyle).setOrigin(0.5, 0));
        this.audioContainer.add(this.add.text(-375, 190, 'MUSIC VOLUME', this.titleOptionStyle));
        this.audioContainer.add(this.add.text(-375, 340, 'EFFECTS VOLUME', this.titleOptionStyle));

    // Volumen de la música
    for (let i = 1; i < 6; i++) {  
        const musicVolumeButton = this.add.image((i * 70), 190, (this.music.volume / 20 >= i ? 'soundon' : 'soundoff')).setScale(0.65).setOrigin(0.5, 0);
        musicVolumeButton.setInteractive({ useHandCursor: true });
        musicVolumeButton.on('pointerover', () => {
            this.soundService.playSfx('hover');
        });
        musicVolumeButton.on('pointerdown', () => {
            this.soundService.playSfx('click');
            this.music.volume = i * 20;
            for (let j = 1; j <= 5; j++) {  
                const volumeButton = this.audioContainer.getByName(`musicVolumeButton_${j}`);
                if (volumeButton) {
                    volumeButton.setTexture(j <= i ? 'soundon' : 'soundoff');
                }
            }
            const muteButton = this.audioContainer.getByName('musicVolumeButton_0');
            if (muteButton) {
                muteButton.setTexture('soundon');
            }
            this.soundService.modifyVolume(this.music.volume); 
            this.unsavedChanges = true;
        });

        musicVolumeButton.setName(`musicVolumeButton_${i}`);
        this.audioContainer.add(musicVolumeButton);
    }

    // Mute button
    const muteButton = this.add.image(0, 190, this.music.volume === 0 ? 'soundoff' : 'soundon').setScale(0.65).setOrigin(0.5, 0);
    muteButton.setInteractive({ useHandCursor: true });
    muteButton.on('pointerover', () => {
        this.soundService.playSfx('hover');
    });
    muteButton.on('pointerdown', () => {
        this.soundService.playSfx('click');
        this.music.volume = 0;
        for (let j = 1; j <= 5; j++) {
            const volumeButton = this.audioContainer.getByName(`musicVolumeButton_${j}`);
            if (volumeButton) {
                volumeButton.setTexture('soundoff');
            }
        }
        this.soundService.modifyVolume(this.music.volume); 
        muteButton.setTexture('soundoff');
        this.unsavedChanges = true;
    });
    muteButton.setName('musicVolumeButton_0');
    this.audioContainer.add(muteButton);

    // Volumen de los Efectos de Sonido
    for (let i = 1; i < 6; i++) {  
        const fxsoundVolumeButton = this.add.image((i * 70), 340, (this.fxsound.volume / 20 >= i ? 'soundon' : 'soundoff')).setScale(0.65).setOrigin(0.5, 0);
        fxsoundVolumeButton.setInteractive({ useHandCursor: true });
        fxsoundVolumeButton.on('pointerover', () => {
            this.soundService.playSfx('hover');
        });
        fxsoundVolumeButton.on('pointerdown', () => {
            this.soundService.playSfx('click');
            this.fxsound.volume = i * 20;

            for (let j = 1; j <= 5; j++) {  
                const volumeButton = this.audioContainer.getByName(`fxsoundVolumeButton_${j}`);
                if (volumeButton) {
                    volumeButton.setTexture(j <= i ? 'soundon' : 'soundoff');
                }
            }
            const muteFxButton = this.audioContainer.getByName('fxsoundVolumeButton_0');
            if (muteFxButton) {
                muteFxButton.setTexture('soundon');
            }
            this.soundService.modifySfxVolume(this.fxsound.volume); 
            this.unsavedChanges = true;
        });
        fxsoundVolumeButton.setName(`fxsoundVolumeButton_${i}`);
        this.audioContainer.add(fxsoundVolumeButton);
    }

    const muteFxButton = this.add.image(0, 340, this.fxsound.volume === 0 ? 'soundoff' : 'soundon').setScale(0.65).setOrigin(0.5, 0);
    muteFxButton.setInteractive({ useHandCursor: true });
    muteFxButton.on('pointerover', () => {
        this.soundService.playSfx('hover');
    });
    muteFxButton.on('pointerdown', () => {
        this.soundService.playSfx('click');
        this.fxsound.volume = 0;
        for (let j = 1; j <= 5; j++) {
            const volumeButton = this.audioContainer.getByName(`fxsoundVolumeButton_${j}`);
            if (volumeButton) {
                volumeButton.setTexture('soundoff');
            }
        }
        this.soundService.modifySfxVolume(this.fxsound.volume); 
        muteFxButton.setTexture('soundoff');
        this.unsavedChanges = true;
    });
    muteFxButton.setName('fxsoundVolumeButton_0');
    this.audioContainer.add(muteFxButton);
    }

    initGameSection() {

        const battleTimeOptions = [
            { label: '5 minutes', value: '05:00' },
            { label: '10 minutes', value: '10:00' },
            { label: '15 minutes', value: '15:00' },
        ];

        this.battleTimeContainer = this.add.container(0, 150);
        this.gameContainer.add(this.battleTimeContainer);
        let battleTimeText = this.add.text(55, -15, battleTimeOptions[this.battleTimePosition].label.toUpperCase(), this.controlsKeyStyle);
        this.battleTimeContainer.add(battleTimeText);
        
        const leftArrow = this.add.image(0, 0, 'leftArrow').setScale(0.55);
        leftArrow.setInteractive({ useHandCursor: true }).on('pointerdown', () => {
            this.soundService.playSfx('click');
            this.battleTimePosition = (this.battleTimePosition -1 + battleTimeOptions.length) % battleTimeOptions.length;
            battleTimeText.setText(battleTimeOptions[this.battleTimePosition].label.toUpperCase());
            this.battleTime = battleTimeOptions[this.battleTimePosition]
            this.unsavedChanges = true;
        });
        
        const rightArrow = this.add.image(250, 0, 'rightArrow').setScale(0.55);
        rightArrow.setInteractive({ useHandCursor: true }).on('pointerdown', () => {
            this.soundService.playSfx('click');
            this.battleTimePosition = (this.battleTimePosition + 1) % battleTimeOptions.length;
            battleTimeText.setText(battleTimeOptions[this.battleTimePosition].label.toUpperCase());
            this.battleTime = battleTimeOptions[this.battleTimePosition];
            this.unsavedChanges = true;
        });

        this.battleTimeContainer.add(leftArrow);
        this.battleTimeContainer.add(rightArrow);

        this.gameContainer.add(this.add.text(0, 0, 'GAME OPTIONS', this.titleSectionStyle).setOrigin(0.5, 0));
        this.gameContainer.add(this.add.text(-375, 130, 'BATTLE TIME', this.titleOptionStyle));
    }

    initVideoSection() {
        this.videoContainer.add(this.add.text(0, 0, 'VIDEO OPTIONS', this.titleSectionStyle).setOrigin(0.5, 0));
        this.videoContainer.add(this.add.text(0, 130, 'RESOLUTION', this.titleOptionStyle).setOrigin(0.5, 0));

        let selectedOption = null;

        const resolutions = [
            { width: 800, height: 600 },
            { width: 1024, height: 768 },
            { width: 1280, height: 720 },
            { width: 1920, height: 1080 },
        ];

        resolutions.forEach((resolution, index) => {
            let text = this.add.text(0, 130 + ((index + 1) * 60), resolution.width + "x" + resolution.height, this.titleOptionStyle)
            .setStyle({
                strokeThickness: 0
            }).setOrigin(0.5, 0);

            if(resolution.width == this.resolution.width && resolution.height == this.resolution.height){
                text.setStyle({
                    strokeThickness: 6
                });
                selectedOption = text;
            }

            text.setInteractive({ useHandCursor: true });

            text.on('pointerover', function(){
                if(text !== selectedOption){
                    this.soundService.playSfx('hover');
                    text.setColor('#DBD01C');
                    text.setStroke('#000000', 3);
                }
            });

            text.on('pointerout', function(){
                if(text !== selectedOption){
                    text.setStroke('#000000', 0);
                }
                text.setColor('#FFFFFF');
            });

            text.on('pointerdown', () => {
                if(text !== selectedOption){
                    this.soundService.playSfx('click');
                    if(selectedOption){
                        selectedOption.setStyle({
                            strokeThickness: 0
                        })
                    }
                    selectedOption = text;

                    text.setStyle({
                        strokeThickness: 6
                    });

                    let resolution = text.text.split('x');
                    this.resolution.width = resolution[0];
                    this.resolution.height = resolution[1];
                    this.unsavedChanges = true;
                }
            });

            this.videoContainer.add(text);
        });
    }

    initControlsSection() {
        this.controlsContainer.add(this.add.text(0, 0, 'CONTROLS OPTIONS', this.titleSectionStyle).setOrigin(0.5, 0));

        this.createControlsSection(-245, 'p1', -385, -175, this.controlsp1);
        this.createControlsSection(230, 'p2', 90, 300, this.controlsp2);
    }

    // MOSTRAR CONTROLES EN PANTALLA
    createControlsSection(imagePositionX, imagen, labelPositionX, controlPositionX, controls){
        this.controlsContainer.add(this.add.image(imagePositionX, 125, imagen));

        const labels = ['UP/JUMP', 'DOWN', 'LEFT', 'RIGHT', 'ATTACK', 'KICK'];
        const controlKeys = ['up', 'down', 'left', 'right', 'attack', 'kick'];

        labels.forEach((label, index) => {
            const posicionY = 190 + (index * 60);
            this.controlsContainer.add(this.add.text(labelPositionX, posicionY, label, this.controlsOptionStyle));

            const controlKeyText = this.add.text(controlPositionX, posicionY, controls[controlKeys[index]], this.controlsKeyStyle).setOrigin(0.5, 0);
            controlKeyText.setInteractive();
            controlKeyText.on('pointerover', () => {
                this.soundService.playSfx('hover');
                controlKeyText.setStyle({
                    color: '#DBD01C',
                    stroke: '#000000',
                    strokeThickness: 6
                });
            });
            controlKeyText.on('pointerout', () => {
                controlKeyText.setStyle({
                    color: '#FFFFFF',
                    strokeThickness: 0
                });
            });

            controlKeyText.on('pointerdown', () => {
                this.soundService.playSfx('click');
                this.reassignControls(controlKeyText, controlKeys[index], controls);
            });
            this.controlsContainer.add(controlKeyText);
        });
    }

    reassignControls(controlKeyText, controlKey, controls){
        // Mostramos el mensaje para pulsar la nueva tecla
        const reassignMessage = this.add.text(
            this.cameras.main.width / 2,
            this.cameras.main.height / 2,
            'Press a new key...', this.messagePopUp)
        .setOrigin(0.5, 0.5)
        .setDepth(1000);

        this.input.enabled = false;

        this.input.keyboard.once('keydown', (event) => {
            const newKey = event.key.toUpperCase();

            // Añadimos todos los controles actuales a un array y comprobamos si la tecla pulsada ya existe
            const controlsJoined = [
                this.controlsp1.up,
                this.controlsp1.down,
                this.controlsp1.left,
                this.controlsp1.right,
                this.controlsp1.attack,
                this.controlsp1.jump,
                this.controlsp2.up,
                this.controlsp2.down,
                this.controlsp2.left,
                this.controlsp2.right,
                this.controlsp2.attack,
                this.controlsp2.jump,
            ];
            const keyAssigned = Object.values(controlsJoined).includes(newKey);

            // En caso de existir mostramos el error y no se cambia nada
            // En caso contrario se modifica la tecla
            if(keyAssigned){
                const errorMessage = this.add.text(
                    this.cameras.main.width / 2,
                    this.cameras.main.height / 2,
                    'Key already assigned, Please choose another key', this.messagePopUp)
                .setOrigin(0.5, 0.5)
                .setDepth(1200);

                this.time.delayedCall(1000, () => {
                    errorMessage.destroy();
                    reassignMessage.destroy();
                    this.input.enabled = true;
                });
            }
            else{
                controls[controlKey] = newKey;
                controlKeyText.setText(newKey);
                this.unsavedChanges = true;
    
                reassignMessage.destroy();
                this.input.enabled = true;
            }
        })
    }

    saveSettings() {
        this.applySoundChanges();
        localStorage.setItem('audioMusic', JSON.stringify(this.music));
        localStorage.setItem('audioFXSound', JSON.stringify(this.fxsound));
        localStorage.setItem('gameResolution', JSON.stringify(this.resolution));
        localStorage.setItem('battleTime', JSON.stringify(this.battleTime));
        localStorage.setItem('controlsP1', JSON.stringify(this.controlsp1));
        localStorage.setItem('controlsP2', JSON.stringify(this.controlsp2));
        localStorage.setItem('battleTimePosition', JSON.stringify(this.battleTimePosition))
        this.unsavedChanges = false;
    }

    applySoundChanges(){

        if (this.music.playing) {
            window.musicState.playing = true;
        } else {
            window.musicState.playing = false;
        }
        this.soundService.modifyVolume(this.music.volume);  
        this.soundService.modifySfxVolume(this.fxsound.volume);       
    }

    exitToMenu() {
        if (this.unsavedChanges) {
            const confirmationText = this.add.text(
                this.cameras.main.width / 2,
                this.cameras.main.height / 2,
                'You are going to save your changes. Do you want to continue?', this.messagePopUp)
            .setOrigin(0.5, 0.5)
            .setDepth(1000);

            const yesButton = this.add.text(this.cameras.main.width / 2 - 50, this.cameras.main.height / 2 + 50, 'Yes', {
                fontSize: '24px',
                color: '#FFFFFF',
                align: 'center',
                fontStyle: 'bold'
            })
            .setOrigin(0.5, 0.5)
            .setInteractive({ useHandCursor: true })
            .on('pointerover', () => {
                yesButton.setStyle({
                    stroke: '#000000',
                    strokeThickness: 3
                });
            })
            .on('pointerout', () => {
                yesButton.setStyle({
                    strokeThickness: 0
                });
            })
            .on('pointerdown', () => {
                this.saveSettings();
                this.scene.stop('Settings');
                this.scene.start('Menu');
            });

            const noButton = this.add.text(this.cameras.main.width / 2 + 50, this.cameras.main.height / 2 + 50, 'No', {
                fontSize: '24px',
                color: '#FFFFFF',
                align: 'center',
                fontStyle: 'bold'
            })
            .setOrigin(0.5, 0.5)
            .setInteractive({ useHandCursor: true })
            .on('pointerover', () => {
                noButton.setStyle({
                    stroke: '#000000',
                    strokeThickness: 3
                });
            })
            .on('pointerout', () => {
                noButton.setStyle({
                    strokeThickness: 0
                });
            })
            .on('pointerdown', () => {
                this.input.enabled = true;
                confirmationText.destroy();
                yesButton.destroy();
                noButton.destroy();
            });
        }else {
            this.soundService.playSfx('click');
            this.input.enabled = true;
            this.scene.stop('Settings');
            this.scene.start('Menu');
        }
    }
}