import axios from 'axios';
import { validateEmail, validatePasswordLength, validatePasswordComplexity, validateUsernameLength } from './authValidations';
import { userInfoStore, getCookie } from './storeService';

class AuthRequest {
    constructor(baseURL) {
        this.api = axios.create({
            baseURL,
            headers: { 'Content-Type': 'application/json' },
        });

        this.tokenKey = 'access_token';

        this.api.interceptors.request.use((config) => {
            const token = this.getToken()
            
            if (token) {
                config.headers.Authorization = `Bearer ${token}`;
            }
            return config;
        });

        this.api.interceptors.response.use(
            (response) => response,
            (error) => {
                return Promise.reject(error);
            }
        );
    }

    async login(email, password) {
        if (!email || !password) {
            throw new Error('Please provide both email and password.');
        }
        if (!validateEmail(email)) {
            throw new Error('Invalid email format.');
        }
        if (!validateUsernameLength(email)) {
            throw new Error('Email must be between 3 and 250 characters long.');
        }
        if (!validatePasswordLength(password)) {
            throw new Error('Password must be at least 8 characters long.');
        }

        try {
            const response = await this.api.post('/kb/login', { 
                email, password
             });
            if (!response.data.original.access_token) {
                throw new Error('Invalid username or password.');
            }

            this.setToken(response.data.original);

            return response;
        } catch (error) {
            throw new Error(`${error.response.data.original.message}`);
        }
    }

    async register(username, email, password) {
        if (!email || !password) {
            throw new Error('Please fill all fields.');
        }
        if (!validateEmail(email)) {
            throw new Error('Invalid email format.');
        }
        if (!validateUsernameLength(email)) {
            throw new Error('Email must be between 3 and 20 characters long.');
        }
        if (!validatePasswordLength(password)) {
            throw new Error('Password must be at least 8 characters long.');
        }
        if (!validatePasswordComplexity(password)) {
            throw new Error('Password must contain at least one number, one uppercase letter, and one lowercase letter');
        }
        
        try {
            const response = await this.api.post('/kb/register', { 
                name: username,
                email: email,
                password: password
             });
            if (!response.data.original.access_token) {
                throw new Error('Failed in register. Try again later.');
            }

            this.setToken(response.data.original);

            return response;
        } catch (error) {
            console.log('register error', error)
            throw new Error(`${error.response.data.original.error[0]}`);
        }
    }

    async logout(id) {
        try {
            const response = await this.api.post('/kb/logout',{
                id: id,
            });
            return response.data;
        } catch (error) {
            throw new Error(`Request failed: ${error.message}`);
        }
    }


    async request(endpoint, method = 'GET', data = {}) {
        try {
            const response = await this.api.request({
                url: endpoint,
                method,
                data: method === 'GET' ? undefined : data,
            });
            return response.data;
        } catch (error) {
            throw new Error(`Request failed: ${error.message}`);
        }
    }

    /**
     * Llama al método del servicio encargado de crear la cookie para almacenar el token de acceso a la aplicación.
     * @param {JsonWebToken} userInfo - Información necesaria del usuario
     */
    setToken(userInfo) {
        userInfoStore(userInfo)
    }

    getToken() {
        return getCookie('userInfo', 'access_token')
    }

    isAuthenticated() {
        return !!this.getToken();
    }

    /**
     * Elimina el token de la cookie y lo revoca en el backend (API)
     */
    logout() {
        localStorage.removeItem(this.tokenKey);
    }
}

export default AuthRequest;