import { Scene } from 'phaser';
import SoundService from '../services/soundService';

export class IntroScene extends Scene {
    constructor() {
        super({ key: 'IntroScene' });
        this.soundService = new SoundService(this);
        this.music = localStorage.getItem('audioMusic') ? JSON.parse(localStorage.getItem('audioMusic')) : { enable: 1, volume: 60 };
        this.sfxSound = localStorage.getItem('audioFXSound') ? JSON.parse(localStorage.getItem('audioFXSound')) : { enable: 1, volume: 60 };
        this.soundService.applyGlobalSound({
            volume: this.music.volume/100, 
            playing: this.music.playing,
            sfxVolume: this.sfxSound.volume/100,
            sfxEnable: this.sfxSound.enable
        });
    }

    preload() {
        this.load.setPath('assets/');
        this.load.image('logoZaidin', 'images/background/logoZaidin.png');
    }

    create() {
        this.cameras.main.setBackgroundColor('#000000');
        const logo = this.add.image(this.cameras.main.centerX, this.cameras.main.centerY, 'logoZaidin');
        logo.setDisplaySize(500, 500);
        this.cameras.main.fadeIn(3000, 0, 0, 0);
        this.time.delayedCall(4000, () => {
            this.cameras.main.fadeOut(2500, 0, 0, 0);
        });

        this.cameras.main.once('camerafadeoutcomplete', () => {
            this.scene.start('Login', { soundService: this.soundService });
        });
    }
}

export default IntroScene;
