import { Scene } from 'phaser';
import { Player } from '../gameObjects/player.js';
import { Hud } from '../gameObjects/hud.js';
import { Temporizador } from '../utils/temporizador.js';
import { Mapa } from '../gameObjects/mapa.js';
import { Estadisticas } from '../utils/estadisticas.js';

class Game extends Scene {

    constructor(){
        super({ key: 'Game' });
        this.ancho = document.body.clientWidth;
        this.alto = document.body.clientHeight;
        this.personajes = [];
        this.nombreMapa = null;
        this.soundService = window.soundService;
    }
    preload (){
        this.reset();
        this.load.setPath('assets/');
        this.mapaSelect = this.registry.get('SelectMap');
        this.selectPer = this.registry.get('SelectCharacters');
        this.hud = new Hud(this, this.selectPer);
        this.soundService.loadMusic('map_sound');
        this.registry.set('map_sound', this.soundService);
    }

    create(){
        this.mapa = new Mapa( this.mapaSelect,this);
        for (let personaje in this.selectPer) {
            this.mapa.setRespawn(personaje,this.selectPer[personaje]);
            this.personajes.push(new Player(this, this.selectPer[personaje]));
        }

        this.mapa.asignaColisiones(this.personajes);
        this.temporizador = new Temporizador(this.registry.get('battleTime').value,this);
        this.estadisticas = new Estadisticas(this.personajes);
        this.personajes.forEach(item => {
            item.asignarColision(this.personajes);
        })  
        this.eventos();
        this.registry.set('idMapa',this.mapaSelect.id)
        this.events.on('shutdown', this.cleanUp, this);
    }

    update(){
        this.personajes.forEach((personaje) => {
            personaje.update();
        });
    }
    
    finalizarPartida(){
        let perGanador = this.personajes.shift();
        let empate = false;

        this.personajes.forEach(player => {  
            perGanador = this.estadisticas.getKills(perGanador) > this.estadisticas.getKills(player) ? perGanador : player;
            empate = this.estadisticas.getKills(perGanador) == this.estadisticas.getKills(player);
        })
        let cartelVictoria = empate ? 'empate': perGanador.player;
        this.add.image(960,300,'cartel-victoria-'+cartelVictoria).setScale(0.13);
        this.scene.pause();

        this.timeOutId = setTimeout(()=>{
            this.scene.start('Estadisticas');
            this.registry.set('estadisticas',this.estadisticas);
        },3000)     
    }

    eventos(){
        this.pause = this.input.keyboard.addKey('ESC');
        this.pause.on('down', () => {
            this.scene.pause();
            this.temporizador.pause();
            this.scene.launch('Pause');
            this.soundService.pauseMusic(); 
        });
    
        this.events.on('resume', function () {
            this.temporizador.renaudar();
            this.soundService.playMusic();
        }, this);

        this.events.on('shutdown', function () {
            this.temporizador.desactivar();
            clearTimeout(this.timeOutId);
            this.soundService.stopMusic();
        }, this);
    }

    reset(){
        this.ancho = document.body.clientWidth;
        this.alto = document.body.clientHeight;
        this.personajes = [];
        this.nombreMapa = null;
    }

    cleanUp() {
        this.input.keyboard.removeAllListeners();
        const keysToRemoveCapture = ['W', 'S', 'A', 'D', 'G', 'H', 'UP', 'DOWN', 'LEFT', 'RIGHT', 'N', 'M', 'T', 'Y', 'ESC', 'ENTER'];
        keysToRemoveCapture.forEach(key => this.input.keyboard.removeCapture(key));
    }
    
}
export { Game }