import { Scale } from "phaser"

const COR_FONDO_X = 90
const COR_FONDO_Y = 57
const COR_VIDA_X = 117
const COR_VIDA_Y = 85
const COR_KILLS_X = 60
const COR_KILLS_Y = 94
const COR_PER_X = 90
const COR_PER_Y = 52
const ESCALA = 0.6
const ESPACIO = 50
const ANCHO = 90
const ESCALA_CABEZA = 0.15


class Hud {
    constructor(scene){
        this.scene = scene;
        this.personajesHud = [];
        this.init();
    }

    init(personajes){
        this.cargarKills()
        this.cargarHud()
       
    }

    cargarKills(){
        let ruta = '/hud/kills/';
        let extension = '.png';
        for(let i = 0; i <= 9; i++){
            this.scene.load.image('kill-'+i,ruta+i+extension);
        }
    }
    cargarHud(){
        this.scene.load.image('fondo-hud','/hud/circulo/fondo.png');
        let rutaVida = '/hud/vida/';
        for(let i = 1; i <= 12; i++){
            this.scene.load.image('vida-'+i,rutaVida+i+'.png');
        }
    }

    cargarPersonajes(personajes){
        let ruta = '/hud/personajes/';
        for(let personaje of personajes){
            this.scene.load.image(personaje+'_fase-1',ruta+personaje+'/fase-1.png');
            this.scene.load.image(personaje+'_fase-2',ruta+personaje+'/fase-2.png');
            this.scene.load.image(personaje+'_fase-3',ruta+personaje+'/fase-3.png');
        }
    }

    add(nomPersonaje,id){
        let corX = this.personajesHud.length * (ESPACIO + ANCHO);
        this.personajesHud.push({
            id:id,
            nombre:nomPersonaje,
            fondo: this.scene.add.image(COR_FONDO_X + corX  , COR_FONDO_Y, 'fondo-hud').setScale(ESCALA),
            vida:   this.scene.add.image(COR_VIDA_X + corX , COR_VIDA_Y , 'vida-12').setScale(ESCALA),
            personaje: this.scene.add.image(COR_PER_X + corX , COR_PER_Y , nomPersonaje+'_fase1').setScale(ESCALA_CABEZA),
            kills:[this.scene.add.image(COR_KILLS_X + corX , COR_KILLS_Y, 'kill-0').setScale(ESCALA)]
        })
    }

    setVida(vida,id){
        let personaje = this.personajesHud.filter(personaje => personaje.id == id ).shift();
        let { x, y } = personaje.vida;
        personaje.vida.destroy();
        personaje.vida = this.scene.add.image(x, y , 'vida-'+vida).setScale(ESCALA);
    }
    
    setKill(numKills,id){
        let count = 0 
        let personaje = this.personajesHud.filter(personaje => personaje.id == id).shift();
        let corX = personaje.kills[0].x;
        personaje.kills.forEach(element => {
           element.destroy();
        });
        

       let digitos = numKills.toString().split('').reverse();
    
       digitos.forEach(digito => {
            personaje.kills.push(this.scene.add.image(corX, COR_KILLS_Y , 'kill-'+digito).setScale(ESCALA));
            corX -= 25;
       })
    }

    setFase(fase,id){
        let personaje = this.personajesHud.filter(personaje => personaje.id == id ).shift();
        let { x, y } = personaje.personaje;
        personaje.personaje.destroy();
        personaje.personaje = this.scene.add.image(x, y , personaje.nombre+'_fase'+fase).setScale(ESCALA_CABEZA);
    }

}

export { Hud }