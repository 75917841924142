import Phaser from 'phaser';
import AuthRequest from '../services/authService';
import GraphicsService from '../services/graphicsService';
import GameService from '../services/gameService';
import SceneActions from '../utils/sceneActions.js';

export class SelectMap extends Phaser.Scene {
    constructor() {
        super({ key: 'SelectMap' });

        this.mapIndex = 0;
        this.authRequest = new AuthRequest(process.env.GET_CHAMPS_URL);
        this.graphicsService = new GraphicsService(this);
        this.gameService = new GameService(this);
        this.sceneActions = new SceneActions(this);
        this.soundService = window.soundService;   
    }

    preload() {
        this.load.setPath('assets/');
        this.maps2 = JSON.parse(localStorage.getItem('maps'));
        this.maps2.forEach((map, index) => {
            this.load.image(`map_preview_${index}`, map.map_preview);
        });
        this.load.image('battleground', 'images/map-select/battleGround.png');
        this.load.image('battleName', 'images/map-select/battleName.png');
        this.load.image('battleSelected', 'images/map-select/battleSelected.png');
        this.load.image('enter', 'images/elements/enter.png');
    }

    async create() {
        await this.loadFonts(['Atlan']);
        this.mapIndex = 0;
        this.selectedMapText = this.add.group();
        this.setupScene();
        this.setupInput();
        await this.gameService.loadData(this.authRequest, process.env.GET_CHAMPS_URL, 'characters', 'personajes');
        this.getMapsAndShow();
        this.gameService.loadCharacterImages(); 
    }

    async loadFonts(fonts) {
        const fontPromises = fonts.map(font => new FontFaceObserver(font).load());
        return Promise.all(fontPromises);
    }

    setupScene() {
        this.cameras.main.fadeIn(1500, 0, 0, 0);
        this.graphicsService.addGeneralBackground('blueSky');
        this.add.image(this.cameras.main.width / 2, this.cameras.main.height / 2 - 350, 'battleground').setOrigin(0.5);
        this.add.image(this.cameras.main.centerX * 0.13, this.cameras.main.centerY * 1.9, 'return');
        this.add.image(this.cameras.main.centerX * 1.9, this.cameras.main.centerY * 1.9, 'enter');
        this.mapCursor = this.add.image(400, this.cameras.main.height / 2.48, 'battleSelected').setAngle(-2);
        this.getMaps();
        this.showSelectedMap(this.mapIndex);
        this.refreshText();
    }

    refreshText() {
        this.mapNames.forEach(mapName => {
            mapName.destroy();
        });
        this.mapNames = [];   
    }

    getMapsAndShow() {
        this.getMaps();
        this.showSelectedMap(this.mapIndex);
    }

    setupInput() {
        this.controlsp1 = localStorage.getItem('controlsP1') ? JSON.parse(localStorage.getItem('controlsP1')) : { up: 'UP', down: 'DOWN', left: 'LEFT', right: 'RIGHT', attack: 'N', kick: 'M' };

        const keyActions = {
            [this.controlsp1.up]: () => this.updateMapIndex(-1, this.soundService.playSfx('hover')),
            [this.controlsp1.down]: () => this.updateMapIndex(1, this.soundService.playSfx('hover')),
            ENTER: () => {
                this.showSelectedMap(this.mapIndex);
                this.registry.set('SelectMap', this.maps2[this.mapIndex]);
                this.soundService.playSfx('click');
                this.sceneActions.movetoScene('SelectChar');
            },
            ESCAPE: () => {
                this.soundService.playSfx('click');
                this.sceneActions.movetoScene('Menu')
            }
        };

        this.input.keyboard.on('keydown', (event) => {
            const action = keyActions[event.key.toUpperCase()];
            if (action) {
                action();
            }
        });
    }

    updateMapIndex(delta) {
        const newIndex = Phaser.Math.Clamp(this.mapIndex + delta, 0, this.maps2.length - 1);
        if (newIndex !== this.mapIndex) {
            this.mapNames[this.mapIndex].setColor('#00003A');
            this.mapIndex = newIndex;
            const selectedTextY = this.textPositions[this.mapIndex];
            this.mapCursor.y = selectedTextY + (this.mapNames[this.mapIndex].height / 2);
            this.showSelectedMap(this.mapIndex);
            this.mapNames[this.mapIndex].setColor('#FFFFFF');
        }
    }

    showSelectedMap(index) {
        const screenHeight = this.cameras.main.centerY;
        const map = this.maps2[index];

        if (this.selectedMapImage) {
            this.selectedMapImage.destroy();
        }

        if (this.selectedMapText && this.selectedMapText.getChildren) {
            this.selectedMapText.getChildren().forEach(letter => letter.destroy());
            this.selectedMapText.clear(true);
        }

        this.selectedMapImage = this.add.image(this.cameras.main.centerX * 1.5, screenHeight, `map_preview_${index}`).setDepth(1);
        const dimensionesRect = this.graphicsService.getRectangleBounds(this.selectedMapImage);
        this.graphicsService.createGraphics(dimensionesRect.x - 5, dimensionesRect.y - 5, dimensionesRect.width + 10, dimensionesRect.height + 10, 0x0000FF, 1);
        const imageCenterMap = this.graphicsService.getImageCenter(this.selectedMapImage);
        const battleSelected = this.add.image(imageCenterMap.centerX, screenHeight * 1.7, 'battleName');
        const imageCenterBattleSelected = this.graphicsService.getImageCenter(battleSelected);

        if (this.selectedMapText) {
            this.selectedMapText.destroy();
        }

        this.selectedMapText = this.add.text(imageCenterBattleSelected.centerX, screenHeight * 1.65, map.name.toLocaleUpperCase(), {
            fontSize: '48px',
            color: '#ffffff',
            fontFamily: 'Atlan',
            fontStyle: 'bold',
            align: 'center',
        }).setOrigin(0.5, 0);
    }

    getMaps() {

        this.textPositions = [];
        this.mapNames = [];
        this.maps2.forEach((map, index) => {
            const y = this.cameras.main.height / 2.65 + index * 100;
            this.textPositions.push(y);
            const mapName = this.add.text(380, y, map.name.toLocaleUpperCase(), {
                fontSize: '48px',
                color: index === 0 ? '#FFFFFF' : '#00003A',
                fontFamily: 'Atlan',
                fontStyle: 'bold',
                align: 'center',
            }).setOrigin(0.5, 0).setAngle(-2)
            .setInteractive({ useHandCursor: true })
            .on('pointerover', () => {
                mapName.setColor('#FFFFFF');
                this.updateMapIndex(index - this.mapIndex, this.soundService.playSfx('hover'));
            })
            .on('pointerdown', () => {
                this.showSelectedMap(index);
                this.registry.set('SelectMap', this.maps2[index]);
                this.soundService.playSfx('click');
                this.sceneActions.movetoScene('SelectChar');
            }).on('pointerout', () => {
                mapName.setColor('#00003A');
            });
            this.mapNames.push(mapName);
        });
    }
}
