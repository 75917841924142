import SoundService from './services/soundService';

window.musicState = {
    title: false,
    playing: false,
    volume: 1,
    enable: 1
};

window.sfxState = {
    volume: 1,
    enable: 1
};

window.soundService = new SoundService();


import { Boot } from './scenes/Boot';
import { Game } from './scenes/Game';
import { Login } from './scenes/Login';
import { IntroScene } from './scenes/IntroScene';
import { Settings } from './scenes/Settings';
import { Menu } from './scenes/Menu';
import { Credits } from './scenes/Credits';
import { Stats } from './scenes/Stats';
import { SelectChar } from './scenes/SelectChar';
import { SelectMap } from './scenes/SelectMap';
import { Loading } from './scenes/Loading';
import { Estadisticas } from './scenes/Estadisticas';
import { Pause } from './scenes/Pause';
import 'dotenv/config'; 
import globalState from './services/GlobalState';

const config = {
    type: Phaser.AUTO,
    width: 1920,
    height: 1080,
    parent: 'game-container',
    scale: {
        mode: Phaser.Scale.EXACT_FIT, 
        autoCenter: Phaser.Scale.CENTER_BOTH
    },
    scene: [
        Boot,
        Game,
        Estadisticas,
        Login,
        IntroScene,
        Settings,
        Menu,
        Credits,
        Stats,
        SelectChar,
        SelectMap,
        Loading,
        Pause,
    ],
    physics : {
        default : 'arcade',
        arcade :{
            gravity : { y: 400 },
            debug : false,
        }
    },
    dom: {
        createContainer: true, 
    },
};

globalState.setMusicState({
    title: false,
    playing: false,
    volume: 1,
});

export default new Phaser.Game(config);
