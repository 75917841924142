import { getCookie } from '../services/storeService.js';
import AuthRequest from '../services/authService.js';

class Estadisticas {
    constructor(personajes) {
        this.estadisticas = new Map();
        this.init(personajes);
    }

    init(personajes) {
        personajes.forEach(personaje => {
            this.estadisticas.set(personaje, {
                danio: 0,
                kills:0,
                muertes:0,
                rachaActual: 0,
                mayorRacha:0,
                primeraMuerte:0
            })
        });
    }

    addDanio(personaje, danio) {
        this.estadisticas.get(personaje).danio += danio;
    }
    addKill(personaje){
        let { p1, p2 } = this.clavesEstadisticas();
        let killP1 = this.estadisticas.get(p1).kills;
        let killP2 = this.estadisticas.get(p2).kills;
        if(killP1 == 0 && killP2 == 0) this.estadisticas.get(personaje).primeraMuerte ++;
        this.estadisticas.get(personaje).kills ++;
        this.estadisticas.get(personaje).rachaActual ++;

    }
    addMuerte(personaje){
        this.estadisticas.get(personaje).muertes ++;
        this.actualizarRacha(personaje);
    }
    addPrimeraMuerte(personaje) {
        this.estadisticas.get(personaje).primeraMuerte++;
    }
    getKills(personaje) {
        return this.estadisticas.get(personaje).kills;
    }

    getStastFinales(){
        let { p1, p2 } = this.clavesEstadisticas();
        let clavesPrimarias = { 'DAMAGE':'danio','KILLS':'kills','DIES':'muertes','LARGEST KILL SPREE':'mayorRacha','FIRST BLOOD':'primeraMuerte'};
        let count = 0;
        let result = {};
        for(let item in clavesPrimarias){
            this.actualizarRacha(p1);
            this.actualizarRacha(p2);
            result[item] = {}
            result[item][p1.name] = this.estadisticas.get(p1)[clavesPrimarias[item]];
            result[item][p2.name] = this.estadisticas.get(p2)[clavesPrimarias[item]];
        }
        return result
    }

    clavesEstadisticas() {
        let statsArray = [... this.estadisticas].map(item => {
            return item.shift();
        });
        return {
            p1: statsArray.shift(),
            p2: statsArray.shift()
        }
    }
    guardarEstadisticas(idMapa) {

        let id = getCookie('userInfo', 'id');
        let { p1, p2 } = this.clavesEstadisticas();
        let estadisticasTotales = {
            "user_id": id,
            "total_damage": 0,
            "total_kills": 0,
            "total_dies": 0,
            "mapa_id": idMapa,
            "personaje1_id": p1.id,
            "personaje2_id": p2.id,
            "largest_kill_spree": 0
        };
        let mayorRacha = 0;
        [... this.estadisticas].forEach(item => {
            let stats = item.pop();
            estadisticasTotales.total_damage += stats.danio
            estadisticasTotales.total_kills += stats.kills
            estadisticasTotales.total_dies += stats.muertes
            mayorRacha = stats.mayorRacha < mayorRacha ? mayorRacha : stats.mayorRacha;
       });
       estadisticasTotales.largest_killl_spree = mayorRacha
       this.subirDatos(estadisticasTotales);
    }
    subirDatos(datos) {
        let authRequest = new AuthRequest('https://api.kingdombattle.es/kb/historial');
        authRequest.request('https://api.kingdombattle.es/kb/historial','POST',datos);
    }
    actualizarRacha(personaje){
        let stats = this.estadisticas.get(personaje);
        stats.mayorRacha = stats.mayorRacha > stats.rachaActual ? stats.mayorRacha : stats.rachaActual;
        stats.rachaActual = 0;
    }
}
export { Estadisticas }