class Mapa {
    constructor(mapa, escena) {
        this.scene = escena;
        this.mapa = mapa;
        this.init();
    }

    init(nombre) {
        this.crearMapa();
        this.crearColisiones();
    }

    crearMapa() {
        this.fondo = this.scene.physics.add.image(960, 540, this.mapa.name.toLowerCase());
        this.fondo.body.allowGravity = false;
        this.fondo.setScale(1, 1);
        this.fondo.body.immovable = true;
        this.scene.physics.world.setBounds(-10,-Infinity,this.scene.scale.width + 20,Infinity)
    }

    crearColisiones() {
        this.partes = this.getCorColisiones().map(parte => {
            let { dimension, cordenada, rotacion } = parte;
            let hitbox = this.scene.add.rectangle(cordenada.x, cordenada.y, dimension.ancho, dimension.alto, 0x6666ff, 0).setOrigin(0, 0);
            if (rotacion) hitbox.setRotation(parte.rotacion);
            this.scene.physics.add.existing(hitbox);
            hitbox.body.allowGravity = false;
            hitbox.body.immovable = true;
            return hitbox;
        });
    }

    asignaColisiones(personajes) {
        personajes.forEach(personaje => this.asignaColision(personaje));
    }
    asignaColision(personaje) {
        this.partes.forEach(parte => {
            this.scene.physics.add.collider(parte, personaje);
        })
        personaje.crearHitbox();
    }

    getCorColisiones() {
        return this.mapa.partes;
    }
    getCorRespawn() {
        return this.mapa.respawn;
    }
    setRespawn(personaje, datos) {
        let respawns = this.getCorRespawn();
        datos['x'] = respawns[personaje].x;
        datos['y'] = respawns[personaje].y;
    }
}

export { Mapa }