import { Scene } from 'phaser';
import AuthRequest from '../services/authService';
import GameService from '../services/gameService';
import GraphicsService from '../services/graphicsService';
import SceneActions from '../utils/sceneActions';
import { deleteCookie } from '../services/storeService';


export class Menu extends Scene {
    constructor() {
        super({ key: 'Menu' });
        this.authRequest = new AuthRequest(process.env.GET_MAPS_URL);
        this.gameService = new GameService(this);
        this.graphicsService = new GraphicsService(this);
        this.sceneActions = new SceneActions(this);
        this.isButtonDisabled = false;
        this.idUser = localStorage.getItem('iduser');
        this.soundService = window.soundService;
    }

    preload() {
        this.load.setPath('assets/');
        this.load.image('blueSky', 'images/menu/blueSkyBackgroundMenu.png');
        this.load.image('menu', 'images/menu/menu.jpg');
        for (let i = 1; i <= 5; i++) {
            this.load.image('button' + i + 'select', 'images/menu/selected/' + i + '.png');
            this.load.image('button' + i + 'unselect', 'images/menu/unselected/' + i + '.png');
        }
        this.load.image('pause', 'images/pause/pause.png');
        this.load.image('return', 'images/elements/return.png');
        this.load.audio('menuMusic', 'music/menu/main.mp3');
        this.load.audio('select', 'sound/select/select.mp3');
        this.load.audio('hover', 'sound/menu/hoverSound.mp3');
    }

    create() {
        this.soundService.setScene(this);
        this.loadMusicSettings();
        this.cameras.main.fadeIn(800, 0, 0, 0);
        this.graphicsService.addGeneralBackground('menu');
        this.gameService.loadDefaultControls();
        const centerY = this.cameras.main.centerY;

        const buttonData = [
            { key: 'button1', unselected: 'button1unselect', selected: 'button1select', targetScene: 'SelectMap', y: centerY - 100, action: async () => await this.gameService.loadData(this.authRequest, process.env.GET_MAPS_URL, 'maps', 'mapas') },
            { key: 'button2', unselected: 'button2unselect', selected: 'button2select', targetScene: 'Stats', y: centerY + 100, action: async () => await this.gameService.loadData(this.authRequest, process.env.GET_CHAMPS_URL, 'characters', 'personajes') },
            { key: 'button3', unselected: 'button3unselect', selected: 'button3select', targetScene: 'Settings', y: centerY },
            { key: 'button4', unselected: 'button4unselect', selected: 'button4select', targetScene: 'Credits', y: centerY + 200, action: () => this.soundService.stopMusic() },
            { key: 'button5', unselected: 'button5unselect', selected: 'button5select', targetScene: 'Login', y: centerY + 300, action: () => { this.soundService.stopMusic(); deleteCookie('userInfo'); this.authRequest.logout(this.idUser) } },
        ];
        this.loadButtonData(buttonData);
    }

    loadButtonData(buttonData) {
        this.soundService.addSfx('hover', { volume: window.sfxState.volume });
        this.soundService.addSfx('click', { volume: window.sfxState.volume });
        const centerX = this.cameras.main.centerX;

        buttonData.forEach((data) => {
            const button = this.add.image(centerX * 1.6, data.y, data.unselected)
                .setInteractive({ useHandCursor: true })
                .setOrigin(0.5)
                .on('pointerdown', async () => {
                    if (!this.isButtonDisabled) {
                        this.isButtonDisabled = true;
                        try {
                            this.soundService.playSfx('click');
                            if (data.action) await data.action();
                            this.sceneActions.movetoScene(data.targetScene);
                        } catch (error) {
                            console.error('Error loading data:', error);
                        } finally {
                            this.isButtonDisabled = false;
                        }
                    }
                })
                .on('pointerover', () => {
                    if (!this.isButtonDisabled) {
                        button.setTexture(data.selected);
                        this.soundService.playSfx('hover');
                    }
                })
                .on('pointerout', () => {
                    if (!this.isButtonDisabled) {
                        button.setTexture(data.unselected);
                    }
                });
        });
    }

    loadMusicSettings() {
        // this.music = localStorage.getItem('audioMusic') ? JSON.parse(localStorage.getItem('audioMusic')) : { enable: 1, volume: 60 };   
        this.soundService.loadMusic('menuMusic')
    }
}
