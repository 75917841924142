class Controles {

    constructor(scene, player){
        this.scene = scene;
        this.controles = this.getTeclas(player);
        this.declararTeclas();
    }
    /**
     * Declara las teclas que se utilizaran en el juego
     * @returns {void}
     */
    declararTeclas(){
        for(let key in this.controles){

            this.scene[this.controles[key]] = this.scene.input.keyboard.addKey(this.controles[key]);
        }
    }
    /**
     * Inicia los controles del juego
     * @returns {void}
     */
    getControles(){
       let movimientos = {};
       for(let key in this.controles){
           movimientos[key] = () => this.scene[this.controles[key]] ? this.scene[this.controles[key]].isDown : false;
       }
       return movimientos;
    }

    getTeclas(player){
        let controles = JSON.parse(localStorage.getItem('controls'+player));

        for (const tecla in controles) {
            if (controles[tecla].startsWith('ARROW')) {
                controles[tecla] = controles[tecla].replace('ARROW', '');
            }
        }
        return controles
    }

}
export {Controles}