import GraphicsService from '../services/graphicsService';
import GameService from '../services/gameService';
import { Scene } from 'phaser';

class Estadisticas extends Scene {

    constructor() {
        super({ key: 'Estadisticas' });
        this.graphicsService = new GraphicsService(this);
        this.gameService = new GameService(this);
        this.width = document.body.clientWidth;
    }
    preload() {
        this.load.setPath('assets/');
        this.load.image('aro', 'images/champion-select/aro.png');
        this.load.image('blueSky', 'images/menu/blueSkyBackgroundMenu.png');
        this.load.image('cartel', 'images/post-game/battle-results.png')
        this.load.image('linea', 'images/post-game/linea.png')
        this.load.image('play-again', 'images/post-game/play-again.png')
        this.load.image('bt-menu', 'images/post-game/menu.png')
    }

    create() {
        let personajes = this.registry.get('SelectCharacters');
        this.p1 = personajes['player1'];
        this.p2 = personajes['player2'];
        this.graphicsService.addGeneralBackground('blueSky');
        let aro1 = this.add.image(70, 520, 'aro').setOrigin(0, 0.5);
        let aro2 = this.add.image(1780, 520, 'aro').setOrigin(1, 0.5).setFlipX(true);
        this.add.image((this.width / 2) + 30, 900, 'bt-menu').setOrigin(0, 0.5).setScale(0.87)
        this.add.image((this.width / 2) - 30, 900, 'play-again').setScale(0.22).setOrigin(1, 0.5)
        this.add.image(340, 570, this.p1.nombre + '-preview').setScale(0.7);
        this.add.image(1510, 570, this.p2.nombre + '-preview').setScale(0.7).setFlipX(true);
        this.add.image(960, 50, 'cartel').setScale(0.23).setOrigin(0.5, 0)
        let estadisticas = this.registry.get('estadisticas');
        this.rellenarEstadisticas(estadisticas.getStastFinales());
        estadisticas.guardarEstadisticas(this.registry.get('idMapa'));
        this.bt();
    }

    rellenarEstadisticas(estadisticas) {
        let pos = 380;
        for (let item in estadisticas) {
            this.rellenarEstadistica(item, estadisticas[item], pos);
            pos += 80;
        }
    }

    rellenarEstadistica(estadistica, valor, pos) {
        this.add.text(this.width / 2, pos, estadistica, { fontSize: '32px', color: '#ffffff', fontFamily: 'Atlan', align: 'center', fontStyle: 'bold' }).setOrigin(0.5, 1)
        this.add.text(610, pos, valor[this.p1.nombre], { fontSize: '32px', color: '#ffffff', fontFamily: 'Atlan', align: 'center', fontStyle: 'bold' }).setOrigin(0.5, 1)
        this.add.text(1240, pos, valor[this.p2.nombre], { fontSize: '32px', color: '#ffffff', fontFamily: 'Atlan', align: 'center', fontStyle: 'bold' }).setOrigin(0.5, 1)
        this.add.image(this.width / 2, pos + 20, 'linea').setScale(0.22, 0.6).setOrigin(0.5, 0.5)
    }

    bt() {
        this.salir = this.input.keyboard.addKey('ESC');
        this.volverJugar = this.input.keyboard.addKey('ENTER');
        this['salir'].on('down', () => {
            this.scene.start('Menu');
        });
        this['volverJugar'].on('down', () => {
            this.scene.start('SelectMap');
        });
    }
}
export { Estadisticas }