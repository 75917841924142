import Phaser from 'phaser';
import SceneActions from '../utils/sceneActions.js';

export class Credits extends Phaser.Scene {
    constructor() {
        super({ key: 'Credits' });
        this.sceneActions = new SceneActions(this);
        this.soundService = window.soundService;
    }

    preload() {
        this.load.setPath('assets/');
        this.load.video('creditsVideo', 'video/creditos.mp4'); 
    }

    create() {
        this.music = localStorage.getItem('audioMusic') ? JSON.parse(localStorage.getItem('audioMusic')) : { enable: 1, volume: 60 }; 
        this.cameras.main.fadeIn(1500, 0, 0, 0);
        this.add.image(this.cameras.main.centerX * 0.13, this.cameras.main.centerY * 1.9, 'return').setDepth(1);
        const screenWidth = this.cameras.main.width;
        const screenHeight = this.cameras.main.height;

        const video = this.add.video(screenWidth / 2, screenHeight / 2, 'creditsVideo').setOrigin(0.5, 0.5);
        
        const videoWidth = screenWidth * 0.3; 
        const videoHeight = screenHeight * 0.47; 
        video.setDisplaySize(videoWidth, videoHeight);
        
        video.video.volume = this.music.volume / 100;
        video.play(true);

        video.on('complete', () => {
            this.scene.stop(); 
            this.scene.start('Menu');
        });

        this.setupInput(); 
    }

    setupInput() {      
        this.controlsp1 = localStorage.getItem('controlsP1') ? JSON.parse(localStorage.getItem('controlsP1')) : { up: 'UP', down: 'DOWN', left: 'LEFT', right: 'RIGHT', attack: 'N', kick: 'M' };

        const keyActions = {
            ESCAPE: () => {
                this.soundService.playSfx('click');
                this.sceneActions.movetoScene('Menu')
            }
        }
        this.input.keyboard.on('keydown', (event) => {
            const action = keyActions[event.key.toUpperCase()];
            if (action) {
                action();
            }
        });
    }
}
