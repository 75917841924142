import GraphicsService from '../services/graphicsService';
class SceneActions {
    constructor(scene) {
        this.scene = scene;
        this.graphicsService = new GraphicsService(scene); 
    }
    movetoScene(sceneTarget){
        this.graphicsService.transitionFadeScene();  
        setTimeout(() => {
            this.scene.scene.stop();
            this.scene.scene.start(sceneTarget); 
        }, 1000);
    }

    scenePause(sound,sceneTarget){
        sound.play();
        this.graphicsService.transitionFadeScene();  
        setTimeout(() => {
            this.scene.pause();
            this.scene.scene.start(sceneTarget); 
        }, 1000);
    }

    sceneResume(sound,sceneTarget){
        sound.play();
        this.graphicsService.transitionFadeScene();  
        setTimeout(() => {
            this.scene.resume(sceneTarget);
            this.scene.stop('SelectChar');
        }, 1000);
    }

    simpleMoveScene(sceneTarget){
        this.graphicsService.transitionFadeScene();  
        setTimeout(() => {
            this.scene.scene.stop();
            this.scene.scene.start(sceneTarget); 
        }, 1000);
    }
}

export default SceneActions;