import { Scene } from 'phaser';
import AuthRequest from '../services/authService';
import GraphicsService from '../services/graphicsService';
import SoundService from '../services/soundService';


export class Login extends Scene {
    constructor() {
        super({ key: 'Login' });
        const baseURL = process.env.APIURL;
        this.authRequest = new AuthRequest(baseURL);
        this.mainMessage;
        this.formContainer = null;
        this.formContainer2 = null;
        this.signInContainer = null;
        this.signUpContainer = null;
        this.loginClicked = false;
        this.registerClicked = false;
        this.graphicsService = new GraphicsService(this);
        this.soundService = window.soundService;
        this.music = localStorage.getItem('audioMusic') ? JSON.parse(localStorage.getItem('audioMusic')) : { enable: 1, volume: 60 };
    }

    preload() {
        this.load.setPath('assets/');
        this.load.image('kingdomLogo', 'images/elements/kingdomLogo.png');
        this.load.image('main', 'images/background/main.jpg');
        this.load.video('videoIntro', 'images/elements/login.mp4'); 
    }

    create() {

        const formFontStyle = {
            fontSize: '36px',
            fontFamily: 'Rubik',
            color: '#000000',
            align: 'center',
            fontWeight: 'bold',
            fontStyle: 'bold'
        }

        if (this.authRequest.isAuthenticated()) {
            this.scene.stop('Login');
            this.scene.start('Menu', { soundService: this.soundService });
        }

        this.cameras.main.fadeIn(2500, 0, 0, 0);
        const screenHeight = this.cameras.main.height;
        const screenWidth = this.cameras.main.width;
        const video = this.add.video(screenWidth / 2, screenHeight / 2, 'videoIntro').setOrigin(0.5, 0.5);

        const videoAspectRatio = video.width / video.height;
        const screenAspectRatio = screenWidth / screenHeight;

        let scale;

        if (screenAspectRatio > videoAspectRatio) {
            scale = screenHeight / video.height;
        } else {
            scale = screenWidth / video.width;
        }

        const scaleFactor = 0.24;
        scale *= scaleFactor;
        video.setScale(scale);
        video.setPosition(screenWidth / 2 + 200, screenHeight / 2);
        video.setMute(true);
        video.play(true);

        const darkOverlay = this.graphicsService.createGraphics(0, 0, screenWidth, screenHeight, 0x000000, 0.5);
        darkOverlay.setDepth(-1);
        const width30Percent = screenWidth * 0.3;
        const loginContainer = this.add.container(0, 0);
        const whiteContainer = this.graphicsService.createGraphics(0, 0, width30Percent, screenHeight, 0xffffff, 1);
        loginContainer.add(whiteContainer);

        let loginFormHTML = `
            <form id="login-form">
                <input type="text" id="login-email" class="input-field" placeholder="Email" autocomplete="email" />
                <input type="password" id="login-password" class="input-field" placeholder="Password" autocomplete="current-password" />
                <div class="message-container">
                    <div class="error-message" id="error-message"></div>
                    <div class="success-message" id="success-message"></div>
                </div>
                <button type="submit" class="input-button">
                <img src="assets/images/icons/arrowRight.png" alt="Login" style="height: 24px; width: 24px;" />
                </button>
                <div class="RegisterSesion">
                    <p>Can't sign in?</p>
                    <p><a href="#" id="show-register">Create a new account</a></p>
                    <p>This application is protected by HCaptcha and under privacy policy and terms and conditions</a></p>
                </div>
            </form>
        `;

        let registerFormHTML = `
            <form id="register-form">
                <input type="text" id="register-username" class="input-field" placeholder="Username" autocomplete="username" />
                <input type="text" id="register-email" class="input-field" placeholder="Email" autocomplete="email" />
                <input type="password" id="register-password" class="input-field" placeholder="Password" autocomplete="new-password" />
                <input type="password" id="confirm-password" class="input-field" placeholder="Confirm Password" autocomplete="new-password" />
                <div class="message-container">
                    <div class="error-message" id="error-message-register"></div>
                    <div class="success-message" id="success-message-register"></div>
                </div>
                <button type="submit" class="input-button">
                    <img src="assets/images/icons/arrowRight.png" alt="Register" style="height: 16px; width: 16px;" />
                </button>
                <div class="LoginSesion">
                    <p><a href="#" id="show-login">I already have an account</a></p>
                </div>
            </form>
        `;

        this.formContainer = this.add.dom(width30Percent / 2, screenHeight / 1.5).createFromHTML(loginFormHTML);
        loginContainer.add(this.formContainer);

        this.formContainer2 = this.add.dom(width30Percent / 2, screenHeight / 1.5).createFromHTML(registerFormHTML);
        loginContainer.add(this.formContainer2);

        this.formContainer.setVisible(false);
        this.formContainer2.setVisible(false);

        const logo = this.add.image(width30Percent / 2, screenHeight / 4 - 80, 'kingdomLogo');
        loginContainer.add(logo);
        logo.setDisplaySize(500, 300);

        this.signInContainer = this.add.container(screenWidth / 8.8, screenHeight / 3.4);
        const signIn = this.add.text(0, 0, 'Sign In', formFontStyle);
        this.signInContainer.add(signIn);

        this.signUpContainer = this.add.container(screenWidth / 8.8, screenHeight / 3.4);
        const signUp = this.add.text(0, 0, 'Sign Up', formFontStyle);
        this.signUpContainer.add(signUp);
        this.signUpContainer.setVisible(false);

        this.setupUI();
    }

    setupUI() {
        this.time.delayedCall(350, () => {
            this.tweens.add({
                targets: [this.formContainer, this.signInContainer],
                alpha: 1,
                duration: 10000,
                ease: 'Power2'
            });
            this.showLoginForm();
        });
    }

    async loginUser(email, password) {
        const errorMessage = document.getElementById('error-message');
        errorMessage.style.display = 'none';
        this.showMessage(true, 'Connecting...');

        await new Promise(resolve => setTimeout(resolve, 500));

        try {
            const res = await this.authRequest.login(email, password);
            if (res.status === 200) {
                localStorage.setItem('iduser', res.data.original.id)
                this.showMessage(true, res.data.original.message);
                setTimeout(() => {
                    this.graphicsService.transitionFadeScene();
                }, 250)
                setTimeout(() => {
                    this.scene.stop('Login');
                    if (this.soundService.playingMusic()) {
                        this.soundService.stopMusic();
                    }
                    this.scene.start('Menu', { soundService: this.soundService });
                }, 600);
            } else {
                this.showMessage(false, 'Incorrect login');
            }
        } catch (error) {
            this.showMessage(false, error.message);
        }
    }

    async registerUser(username, email, password) {
        const errorMessage = document.getElementById('error-message-register');
        errorMessage.style.display = 'none';
        this.showMessage(true, 'Registering user...', true);

        await new Promise(resolve => setTimeout(resolve, 500));

        try {
            const res = await this.authRequest.register(username, email, password);
            if (res.status === 200) {
                this.showMessage(true, res.data.original.message, true);
                setTimeout(() => {
                    this.graphicsService.transitionFadeScene();
                }, 250)
                setTimeout(() => {
                    if (this.soundService.playingMusic()) {
                        this.soundService.stopMusic();
                    }
                    this.scene.start('Menu', { soundService: this.soundService });
                }, 1000);
            } else {
                setTimeout(() => {
                    this.showMessage(false, res.message, true);
                }, 500);
            }
        } catch (error) {
            this.showMessage(false, error.message, true);
        }
    }

    showLoginForm() {
        const click = this.sound.add('click');
        this.signUpContainer.setVisible(false);
        this.signInContainer.setVisible(true);
        this.formContainer.setVisible(true);
        this.formContainer2.setVisible(false);
        this.formContainer.removeListener('submit');
        this.formContainer.addListener('submit').on('submit', (event) => {
            event.preventDefault();
            click.play();
            const form = document.getElementById('login-form');
            const email = form.querySelector('input[id="login-email"]').value;
            const password = form.querySelector('input[id="login-password"]').value;
            this.loginUser(email, password);
        });
        document.getElementById('show-register').addEventListener('click', (event) => {
            event.preventDefault();
            if (!this.registerClicked) {
                click.play();
                this.registerClicked = true;
                this.loginClicked = false;
                this.showRegisterForm();
            }
        });
    }

    showRegisterForm() {
        const click = this.sound.add('click');
        this.signUpContainer.setVisible(true);
        this.signInContainer.setVisible(false);
        this.formContainer.setVisible(false);
        this.formContainer2.setVisible(true);
        this.formContainer2.removeListener('submit');
        this.formContainer2.addListener('submit').on('submit', (event) => {
            event.preventDefault();
            click.play();
            const form = document.getElementById('register-form');
            const username = form.querySelector('input[id="register-username"]').value;
            const email = form.querySelector('input[id="register-email"]').value;
            const password = form.querySelector('input[id="register-password"]').value;
            const confirmPassword = form.querySelector('input[id="confirm-password"]').value;
            if (password === confirmPassword) {
                this.registerUser(username, email, password);
            } else {
                document.getElementById('error-message-register').textContent = 'Passwords must match.';
            }
        });
        document.getElementById('show-login').addEventListener('click', (event) => {
            event.preventDefault();
            if (!this.loginClicked) {
                click.play();
                this.registerClicked = false;
                this.loginClicked = true;
                this.showLoginForm();
            }
        });
    }

    showMessage(isSuccess, message, register = false) {
        let errorMessage = null, successMessage = null;
        if (!register) {
            errorMessage = document.getElementById('error-message');
            successMessage = document.getElementById('success-message');
        } else {
            errorMessage = document.getElementById('error-message-register');
            successMessage = document.getElementById('success-message-register');
        }

        if (isSuccess) {
            successMessage.textContent = message;
            successMessage.style.display = 'block';
            errorMessage.style.display = 'none';
        } else {
            errorMessage.textContent = message;
            errorMessage.style.display = 'block';
            successMessage.style.display = 'none';
        }
    }

}