import { Scene } from 'phaser';

export class Boot extends Scene
{
    constructor () {
        super({ key: 'Boot' });
    }

    preload ()
    {
        this.load.setPath('assets/');
        this.load.audio('click', 'sound/login/click.mp3');
    }

    create () {
        const rubik = new FontFaceObserver('Rubik');
        const atlan = new FontFaceObserver('Atlan');

        Promise.all([
            rubik.load(),
            atlan.load()
        ]).then(() => {
            this.scene.start('IntroScene');
        });
    }
}
