const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
};

const validatePasswordLength = (password) => {
    const minLength = 8; 
    return password.length >= minLength;
};

const validatePasswordComplexity = (password) => {
    const hasNumber = /\d/.test(password); 
    const hasUpperCase = /[A-Z]/.test(password); 
    const hasLowerCase = /[a-z]/.test(password);

    return hasNumber && hasUpperCase && hasLowerCase;
};

const validateUsernameLength = (username) => {
    const minLength = 3; 
    const maxLength = 250; 
    const usernameLength = username.trim().length;
    return usernameLength >= minLength && usernameLength <= maxLength;
};

export { validateEmail, validatePasswordLength, validateNotEmpty, validatePasswordComplexity, validateUsernameLength };
